<template>
  <aside id="socialNetwork">
    <div>
      <a
        href="https://www.github.com/"
        target="_blank"
        rel="noopener noreferrer"
        ><font-awesome-icon icon="fa-brands fa-github"
      /></a>
    </div>
    <div>
      <a
        href="https://www.linkedin.com/"
        target="_blank"
        rel="noopener noreferrer"
        ><font-awesome-icon icon="fa-brands fa-linkedin"
      /></a>
    </div>
    <div>
      <a
        href="mailto:jeremy.tardini@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        ><font-awesome-icon icon="fa-solid fa-envelope"
      /></a>
    </div>
    <div>
      <a href="tel:+33638369414" target="_blank" rel="noopener noreferrer"
        ><font-awesome-icon icon="fa-solid fa-phone"
      /></a>
    </div>
  </aside>
</template>

<script>
export default {
  name: "navigationSocialNetwork",
  props: {
    index: Number,
  },
  data() {
    return {
      actualTheme: undefined,
    };
  },
  methods: {
    changeTheme() {
      const aside = document.getElementById("socialNetwork");
      const links = document.querySelectorAll("#socialNetwork a");
      links.forEach((el) => {
        el.style.color = this.actualTheme.primary;
      });
      aside.style.backgroundColor = this.actualTheme.background;
      aside.style.color = this.actualTheme.primary;
      aside.style.border = `1px solid ${this.actualTheme.border}`;
    },
    getNewTheme(index) {
      const routes = this.$router.getRoutes();
      const theme = routes[index].path.split("/");
      if (!theme[1]) {
        this.actualTheme = this.$store.state.colors["accueil"];
      } else {
        this.actualTheme = this.$store.state.colors[theme[1]];
      }
      this.changeTheme();
    },
  },
  mounted() {
    this.getNewTheme(this.index)
  },
  watch: {
    index() {
      this.getNewTheme(this.index)
    },
  },
};
</script>


<style lang="scss" scoped>
aside {
  @include cadre;
  bottom: 3%;
  right: 3%;
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  z-index: 10;
  a {
    font-size: 2em;
  }
}

@media (max-width: 500px) {
  aside {
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 25px;
    border-radius: 0 0 25px 25px;
    padding: 15px 0 15px 0 !important;
  }
}
</style>