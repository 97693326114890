<template>
  <div id="Commerce">
    <div class="Commerce_border">
      <div class="Geo1 barreW"></div>
      <div class="Geo2 barreH"></div>
      <div class="Geo3 barreW"></div>
      <div class="Geo4 barreH"></div>
    </div>
    <div class="Commerce">
      <roueCommerce v-if="section.Home"></roueCommerce>
      <cursusComponent v-if="section.Cursus"></cursusComponent>
      <ProjectComponent v-if="section.Project"></ProjectComponent>
      <XpComponent v-if="section.Xp"></XpComponent>
      <SoftskillComponent v-if="section.Softskill"></SoftskillComponent>
    </div>
    <div class="navigation">
      <button
        v-for="item in buttons"
        :key="item"
        class="buttonNav"
        :id="item.id">
        <font-awesome-icon :icon="item.icone" class="iconeNav" />
      </button>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import roueCommerce from "../components/commerce/roueCommerce.vue";
import cursusComponent from "../components/commerce/cursusComponent.vue"
import XpComponent from '../components/commerce/XpComponent.vue'
import ProjectComponent from "../components/commerce/ProjectComponent.vue"
import SoftskillComponent from "../components/commerce/SoftskillComponent.vue"

export default {
  name: "CommerceView",
  components: {
    roueCommerce,
    cursusComponent,
    ProjectComponent,
    XpComponent,
    SoftskillComponent,

  },
  data() {
    return {
      index: undefined,
      compteurScroll: 0,
      myscrollY: 0,
      section: {
        Home: true,
        Cursus: false,
        Xp: false,
        Project: false,
        Softskill: false,
      },
      buttons: {
        buttonHome: {
          id: "Home",
          icone: "fa-solid fa-user",
        },
        buttonCursus: {
          id: "Cursus",
          icone: "fa-solid fa-scroll",
        },
        buttonXp: {
          id: "Xp",
          icone: "fa-solid fa-briefcase",
        },
        buttonProject: {
          id: "Project",
          icone: "fa-solid fa-folder-tree",
        },
        buttonSoftskill: {
          id: "Softskill",
          icone: "fa-solid fa-list-check",
        },
      },
    };
  },
  methods: {
    changeComponent(newComponent) {
      const child = document.querySelector(".Commerce div")
      gsap.to(child, {x: "35px", duration: 0.2})
      gsap.to(child, { x: "-100vw", duration: 0.4, opacity: 0, delay: 0.2})
      setTimeout(() => {
        for(const i in this.section) {
          this.section[i] = false;
        }
        this.section[newComponent.getAttribute("id")] = true
      }, 500)

    },
    showPartSection(e) {

      //get correct node

      let el = e.target;
      while (el.getAttribute("class") != "buttonNav") {
        el = el.parentNode;
      }
      // verify if it's already taken
      if(this.section[el.id]) {
        return
      }
      // remove actually component
      const buttons = document.querySelectorAll(".buttonNav");
      buttons.forEach((button) => {
        button.setAttribute("class", "buttonNav");
      });
      gsap.to(el, { y: -50, ease: "none", rotate: "360deg", duration: 0.2 });
      gsap.to(el, {
        y: 0,
        delay: 0.5,
        duration: 0.2,
        rotate: "0deg",
      });
      el.setAttribute("class", "buttonNav activeButton");
      console.log(el)
      this.changeComponent(el)
    },
  },
  mounted() {
    gsap.to(".Geo1", {
      transformOrigin: "right",
      width: "40%",
      right: "5%",
      duration: 1.5,
    });
    gsap.to(".Geo3", {
      transformOrigin: "left",
      width: "40%",
      left: "5%",
      duration: 1.5,
    });
    gsap.to(".Geo2", { height: "40%", duration: 1.5, delay: 1.5 });
    gsap.to(".Geo4", { height: "40%", duration: 1.5, delay: 1.5 });

    // Nav animation
    const elsNav = document.querySelectorAll(".buttonNav");
    let delay = 0;
    elsNav.forEach((el) => {
      if(el.getAttribute("id") == "Home") {
        el.setAttribute("class", "buttonNav activeButton");
      }
      gsap.to(el, { x: 0, delay: delay, duration: 0.3 });
      delay += 0.3;
      el.addEventListener("click", this.showPartSection)
    });
  },
};
</script>

<style lang="scss" scoped>
h2 {
  color: #fe7f2d;
  text-align: center;
}

#Commerce {
  width: 90%;
  height: 90%;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  .Commerce_border {
    .Geo1 {
      top: 5%;
    }
    .Geo2 {
      top: 5%;
      right: 5%;
    }
    .Geo3 {
      bottom: 5%;
      right: 0;
    }
    .Geo4 {
      bottom: 5%;
      left: 5%;
    }
    .barreW {
      width: 0;
      height: 4px;
      border-radius: 150px;
      background-color: $CommerceBorder;
      position: absolute;
    }
    .barreH {
      width: 4px;
      height: 0;
      border-radius: 150px;
      background-color: $CommerceBorder;
      position: absolute;
    }
  }
  .Commerce {
    display: flex;
    width: 80%;
    max-height: 350px;
    margin: auto;
    justify-content: space-around;
    flex-wrap: wrap;
    @include backgroundCommerce;
    border-radius: 25px;
    transition: 0.2s;
    overflow: hidden ;
  }
  .navigation {
    width: 80%;
    height: 35px;
    @include backgroundCommerce;
    border-radius: 25px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .buttonNav {
      position: relative;
      min-width: 50px;
      bottom: 25px;
      border-radius: 25px;
      transition: 0.3s;
      transform: translateX(-100vw);
      font-size: 1.5em;
      background-color: $CommerceBackground;
      padding: 15px;
      border: 1px solid $CommerceBorder;
      cursor: pointer;
      color: $CommerceText;
    }
    .buttonNav:hover {
      border: 2px solid $CommercePrimary;
      transform: scale(1.2);
      padding: 25px;
    }
    .activeButton {
      border: 2px solid $CommercePrimary !important;
      background-color: $CommerceText !important;
      color: $CommercePrimary !important;
    }
  }
}

@media (min-height: 1200px) {
  .Commerce {
    max-height: 500px!important;
  }
}

@media (max-width: 1023px)  {
  .navButton {
    font-size: 1em!important;
  }
}


</style>
