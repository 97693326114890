<template>
  <div id="windowOrganisationIt">
    <div id="promptContaint"></div>
    <div class="commandeLigne">
      <p id="Ligne_before">{{ path }} ></p>
      <p id="Ligne_commande"></p>
      <p id="Ligne_tiret" :class="{ display: tiretDisable || onLoad }">_</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "windowOrganisationIt",
  data() {
    return {
      tiretDisable: false,
      onLoad: false,
      commandeLigne: undefined,
      promptContaint: undefined,
      beforeContaint: undefined,
      date: undefined,
      path: "~",
      keypadDictionary: [
        "Shift",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "Control",
        "Tab",
        "AltGraph",
        "Alt",
        "Meta",
        "CapsLock",
        "ContextMenu",
        "Delete",
        "End",
        "Insert",
        "PageDown",
        "PageUp",
        "F1",
        "F2",
        "F3",
        "F4",
        "F5",
        "F6",
        "F7",
        "F8",
        "F9",
        "F10",
        "F11",
        "F12",
        "Home",
        "ScrollLock",
        "NumLock",
      ],

      lastCommand: "",
    };
  },
  methods: {
    async annalyzeScript(commande) {
      let allscript = commande.split(" ");
      if (allscript.length > 1) {
        let status = {
          script: allscript[0],
          argument: allscript[1],
        };
        switch (status.script) {
          case "open": {
            this.$store.commit("changeStatusWindow", status);
            break;
          }
          case "close": {
            this.$store.commit("changeStatusWindow", status);
            break;
          }
          case "resize": {
            this.$emit("resize", status.argument);
            break;
          }
          case "cd": {
            this.$store.commit("changePath", status.argument);
            this.path = this.$store.state.pathIt;
            break
          }
          default: {
            let pCommande = document.createElement("p");
            pCommande.textContent += `Sorry but we don't understand the request: "${commande}"`;
            let pMan = document.createElement("p");
            pMan.textContent += `Use "man" for more help`;
            this.promptContaint.append(pCommande, pMan);
            return
          }
        }
      } 
      else {
        switch (commande) {
          case "clear": {
            this.promptContaint.innerHTML = "";
            break;
          }
          case "ls": {
            this.$store.commit("getLs");
            const ul = document.createElement("ul");
            ul.style.width = "50%";
            ul.style.display = "flex";
            ul.style.justifyContent = "space-evenly";
            for (let i = 0; i < this.$store.state.currentLs.length; i++) {
              const li = document.createElement("li");
              li.textContent += this.$store.state.currentLs[i];
              ul.append(li);
            }
            this.promptContaint.append(ul);
            break;
          }
          case "man": {
            this.$store.commit("changeStatusWindow", {
              script: "open",
              argument: "Man",
            });
            break;
          }
          default: {
            let pCommande = document.createElement("p");
            pCommande.textContent += `Sorry but we don't understand the request: "${commande}"`;
            let pMan = document.createElement("p");
            pMan.textContent += `Use "man" for more help`;
            this.promptContaint.append(pCommande, pMan);
            break;
          }
        }
      }
    },
    async validateKey() {
      const p = document.createElement("p");
      const date = this.makeDate();
      p.textContent = date + " : " + this.commandeLigne.innerText;
      this.promptContaint.append(p);
      await this.annalyzeScript(this.commandeLigne.innerText);
      this.lastCommand =this.commandeLigne.innerText;
      this.commandeLigne.innerText = "";
    },
    addTextKey(letter) {
      this.commandeLigne.textContent += letter;
    },
    giveLastCommand() {
      this.commandeLigne.textContent = this.lastCommand;
    },
    removeLastLetter() {
      let myText = this.commandeLigne.textContent.split("");
      this.commandeLigne.innerText = "";
      for (let i = 0; i < myText.length - 1; i++) {
        this.commandeLigne.textContent += myText[i];
      }
    },
    scrollToBottom() {},
    makeDate() {
      const weeks = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
      const date = new Date();
      date.getDay();
      let second = date.getSeconds();
      second = second.toString();
      if (second.length < 2) {
        second = "0" + second;
      }
      let minute = date.getMinutes();
      minute = minute.toString();
      if (minute.length < 2) {
        minute = "0" + minute;
      }
      let hour = date.getHours();
      hour = hour.toString();
      if (hour.length < 2) {
        hour = "0" + hour;
      }
      return `{ ${
        weeks[date.getDay()]
      } ${date.getDate()} ${hour}:${minute}:${second} }`;
    },
    tchekKeyPadCommande(key) {
      let result = false;
      this.keypadDictionary.forEach((el) => {
        if (key == el) {
          result = true;
        }
      });
      return result;
    },
  },
  mounted() {
    this.commandeLigne = document.getElementById("Ligne_commande");
    this.promptContaint = document.getElementById("promptContaint");
    setInterval(() => {
      if (this.tiretDisable) {
        this.tiretDisable = false;
      } else if (!this.tiretDisable) {
        this.tiretDisable = true;
      } else {
        console.log("Error to enable writing, please reload");
      }
    }, 750);
    window.addEventListener("keydown", (e) => {
      e.preventDefault();
      // prevent key from apd
      if (this.tchekKeyPadCommande(e.key)) {
        return;
      }
      // appley correct key
      if (e.key === "Enter") {
        this.validateKey();
      } else if (e.key === "ArrowUp") {
        this.giveLastCommand();
      } else if (e.key == "Backspace") {
        this.removeLastLetter();
      } else if (e.key === " ") {
        this.addTextKey(e.key);
      } else if (e.key.match(/[a-zA-Z]{1}/) || e.key === "." || e.key === "/") {
        this.addTextKey(e.key);
      } else {
        return;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#windowOrganisationIt {
  background-color: black;
  height: 20%;
  position: absolute;
  z-index: 20;
  bottom: 0;
  width: 100%;
  margin: 0 -15px 0 -15px;
  border-top: 1px solid $InformatiquePrimary;
  padding: 10px 0 0 10px;
  font-family: "Ubuntu";
  color: $InformatiqueText;
  overflow: scroll;
  overflow-y: none;
  .commandeLigne {
    display: flex;
    #Ligne_tiret {
      margin: 0 0 0 0;
    }
  }
}
#windowOrganisationIt::-webkit-scrollbar {
  width: 5px;
}
#windowOrganisationIt::-webkit-scrollbar-track {
  background-color: transparent;
}
#windoOrganisationIt::-webkit-scrollbar-thumb {
  color: green;
}

#Ligne_before {
  margin-right: 5px;
}

.display {
  display: none !important;
}
</style>

