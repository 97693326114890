<template>
  <div id="MyStacks">
    <div class="MyStacks_display">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 376.69 175.86"
      >
        <path
          id="Build"
          class="cls-1"
          d="M263.69,305.82A56.86,56.86,0,0,1,254.09,197l-19-25.5A87.9,87.9,0,0,0,251.38,334.8Z"
          transform="translate(-186.66 -162.07)"
        />
        <path
          id="Deploy"
          class="cls-2"
          d="M412.57,233C450,197,469,193.12,475.41,193.12A56.6,56.6,0,0,1,495,196.6L514,171a87.34,87.34,0,0,0-38.62-9c-13.48,0-28.47,5.54-45.82,17-11.93,7.84-24.89,18.44-38.52,31.52A15.53,15.53,0,0,0,412.57,233Z"
          transform="translate(-186.66 -162.07)"
        />
        <path
          id="Plan"
          class="cls-3"
          d="M256.76,196a56.54,56.54,0,0,1,17.83-2.88c11.61,0,30.76,11,52.53,30.23a391.41,391.41,0,0,1,35,35.45l22-22a423.88,423.88,0,0,0-36.44-36.71c-29-25.57-52.88-38-73.08-38a87.33,87.33,0,0,0-37,8.18Z"
          transform="translate(-186.66 -162.07)"
        />
        <path
          id="Integration"
          class="cls-4"
          d="M337.05,267.55c-37.05,35.5-56,39.33-62.46,39.33a57.45,57.45,0,0,1-8.14-.59l-12.4,29.19a87.43,87.43,0,0,0,20.54,2.45c22.49,0,50.73-16.13,83.94-47.95a15.53,15.53,0,0,0-21.48-22.43Z"
          transform="translate(-186.66 -162.07)"
        />
        <path
          id="Monitoring"
          class="cls-5"
          d="M485.63,305.94a57.38,57.38,0,0,1-10.22.94c-12,0-31.93-11.66-54.55-32a386.94,386.94,0,0,1-33.93-34.84l-.09-.1c-.23-.28-.56-.66-.95-1.11l-22,22a417.7,417.7,0,0,0,36,36.86c30,27,54.76,40.19,75.59,40.19A87.87,87.87,0,0,0,498,335Z"
          transform="translate(-186.66 -162.07)"
        />
        <path
          id="Operate"
          class="cls-6"
          d="M516.49,172.29l-18.86,25.35a56.87,56.87,0,0,1-9.28,107.74l12.26,28.86a87.9,87.9,0,0,0,15.88-162Z"
          transform="translate(-186.66 -162.07)"
        />
      </svg>
      <div class="MyStacks_display_intro">
        <h4>DevOps loop</h4>
        <p>
          Cette boucle infine représente le cycle de vie d'une application. De
          la conception de celle-ci, en passant par sa conception, son
          deploiement et son monitoring. Vous pourrez trouver tous les outils
          dont je me suis servie dans le maintient , la creation et le testing
          néccessaires a la bonne creation d'une appllication
        </p>
      </div>
    </div>
    <ul class="MyStacks_tools">
      <h4>{{ NameCategorie }}</h4>
      <div class="MyStacks_tools_li">
        <li v-for="item in currentStacks" :key="item">
          {{ item.name }}
          <img :src="item.src" alt="" />
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  name: "stacksComponent",
  data() {
    return {
      currentStacks: undefined,
      NameCategorie: undefined,
      allStacks: {
        Plan: {
          figma: {
            name: "Figma",
            src: require("../../assets/It/logoTools/Figma.png"),
          },
          Trello: {
            name: "Trello",
            src: require("../../assets/It/logoTools/Trello.png"),
          },
        },
        Build: {
          github: {
            name: "GitHub",
            src: require("../../assets/It/logoTools/github.png"),
          },
          nodeJS: {
            name: "NodeJS",
            src: require("../../assets/It/logoTools/nodejs.png"),
          },
          PrismaORM: {
            name: "Prisma ORM",
            src: require("../../assets/It/logoTools/prisma.png"),
          },
          TypeOrm: {
            name: "TypeORM",
            src: require("../../assets/It/logoTools/typeOrm.png"),
          },
          mysql: {
            name: "MySQL",
            src: require("../../assets/It/logoTools/mysql.png"),
          },
          postgre: {
            name: "PostgreSQL",
            src: require("../../assets/It/logoTools/PostgreSQL.png"),
          },
        },
        Integration: {
          gitLabCi: {
            name: "GitLab CI/CD",
            src: require("../../assets/It/logoTools/gitlab.png"),
          },
          sonarQube: {
            name: "SonarQube",
            src: require("../../assets/It/logoTools/sonarqube.png"),
          },
          selenium: {
            name: "Selenium",
            src: require("../../assets/It/logoTools/selenium.png"),
          },
        },
        Deploy: {
          docker: {
            name: "Docker",
            src: require("../../assets/It/logoTools/docker.png"),
          },
          kubernetes: {
            name: "Kubernetes",
            src: require("../../assets/It/logoTools/Kubernetes.png"),
          },
        },
        Operate: {
          ansible: {
            name: "Ansible",
            src: require("../../assets/It/logoTools/Ansible.png"),
          },
          terraforme: {
            name: "Terraform",
            src: require("../../assets/It/logoTools/terraform.png"),
          },
        },
        Monitoring: {
          aws: {
            name: "Aws Tools",
            src: require("../../assets/It/logoTools/aws.png"),
          },
        },
      },
    };
  },
  methods: {
    showMyStacks(e) {
      let id = e.srcElement.id;
      this.NameCategorie = id
      this.currentStacks = this.allStacks[id];
    },
  },
  mounted() {
    let paths = document.querySelectorAll("#MyStacks path");
    paths.forEach((el) => {
      el.addEventListener("click", this.showMyStacks);
    });
  },
};
</script>

<style lang="scss" scoped>
#MyStacks {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 65vh;
  margin: auto;
  width: 100%;
  padding-top: 50px;
  overflow: scroll;
  .MyStacks_display {
    display: flex;
    width: 90%;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    svg {
      width: 25%;
      min-width: 250px;
      fill: $InformatiquePrimary;
      path {
        transition: 0.3s;
        cursor: pointer;
      }
      path:hover {
        fill: $InformatiqueBackgroundRGBA;
      }
    }
    &_intro {
      width: 60%;
      font-family: "Ubuntu";
      color: $InformatiqueText;
      text-align: center;
      h4 {
        font-size: 1.5em;
      }
      p {
        margin: 15px;
        text-align: justify;
      }
    }
  }

  .MyStacks_tools {
    color: $InformatiqueText;
    font-family: "Ubuntu";
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
    width: 95%;
    margin: auto;
    h4 {
        font-size: 1.5em;
        margin: 15px;
      }
    &_li {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: space-evenly;
      margin: 25px 0 25px 0;
      width: 100%;
    }
    li {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      margin: 5px;
      img {
        width: 120px;
        object-fit: cover;
        margin: auto;
        padding: 15px;
      }
    }
  }
}
#MyStacks::-webkit-scrollbar {
  width: 5px;
}
#MyStacks::-webkit-scrollbar-track {
  background-color: transparent;
}
#MyStacks::-webkit-scrollbar-thumb {
  background-color: $InformatiquePrimary;
  border-radius: 25px
}

@media (max-width: 1023px) {
  .MyStacks_display_intro {
    p {
      
    }
    width: 100%;
    margin-top: 20px;
  }
}
</style>