<template>
  <header id="header">
    <button class="navButton" id="previousNav" v-on:click="previousNav">
      <font-awesome-icon icon="fa-solid fa-arrow-left" />
    </button>
    <nav>
      <h2 id="Title">{{ title }}</h2>
    </nav>
    <button class="navButton" id="nextNav" v-on:click="nextNav">
      <font-awesome-icon icon="fa-solid fa-arrow-right" />
    </button>
  </header>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "navigationHeader",
  props: {
    index: Number,
  },
  data() {
    return {
      outRightClass: false,
      outLeftClass: false,
      inRightClass: false,
      inLeftClass: false,

      title: undefined,
      routes: undefined,
      myActualPath: 0
    };
  },
  methods: {
    previousNav() {
        gsap.to("#Title", { x: "-50vw", ease: "ease", opacity: 0 });
      setTimeout(() => {
        this.$emit("downIndex");
      }, 1000);
    },
    nextNav() {
      gsap.to("#Title", { x: "50vw", ease: "ease", opacity: 0 });
      setTimeout(() => {
        this.$emit("upIndex");
      }, 1000);
    },
    applyNewColor() {
      let header = document.getElementById("header");
      let button = document.querySelectorAll(".navButton");
      let title = document.getElementById("Title");

      header.style.backgroundColor = this.$store.state.actualTheme.background;
      header.style.border = `1px ridge ${this.$store.state.actualTheme.border}`;
      button.forEach((el) => {
        el.style.color = this.$store.state.actualTheme.text;
      });
      title.style.color = this.$store.state.actualTheme.primary;
    },
  },
  mounted() {
    console.log(this.index)
    this.routes = this.$router.getRoutes();
    this.title = this.routes[this.index].name;
    this.applyNewColor();
  },
  watch: {
    index(newIndex) {
      this.title = this.routes[newIndex].name;
      this.$router.push(this.routes[newIndex].path);
      this.applyNewColor();
      gsap.to("#Title", { x: 0, opacity:1})
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  @include cadre;
  z-index: 3;
  top: 3%;
  left: 3%;
  width: 30%;
  min-width: 350px;
  display: flex;
  justify-content: space-around;
  transition: 0.5s;
  overflow: hidden;
  button {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  nav {
    font-size: 2em;
    font-family: "philosopher", serif;
    border: none;
    a {
      text-decoration: none;
      position: relative;
    }
  }
}
@media (max-width: 500px) {
  header {
    bottom: 0%;
    height: 50px;
    left: 0;
    right: 0;
    top: unset;
    width: 100%;
    padding: 0;
    border-radius: 25px 25px 0 0;
  }
}
</style>