import {
  createStore
} from 'vuex'
import router from '../router/index.js'

export default createStore({
  state: {
    actualTheme: {
      primary: "#606c38",
      text: "#283618",
      background: "rgba(254,250,224, 0.3)",
      border: "rgba(254,250,224,0.8)",
      shadow: "5px 5px 15px rgba(255, 255, 255, 0.5);"
    },
    colors: {
      accueil: {
        primary: "#606c38",
        text: "#283618",
        background: "rgba(254,250,224, 0.3)",
        border: "rgba(254,250,224,0.8)",
        shadow: "5px 5px 15px rgba(255, 255, 255, 0.5);"
      },
      informatique: {
        primary: "#75E216",
        text: "#B7C7BA",
        background: "rgba(0,0,0,0.5)",
        border: "#75E216",
        shadow: "5px 5px 15px rgba(255, 255, 255, 0.5);"
      },
      commerce: {
        primary: "#DC851F",
        text: "#08415c",
        background: "rgba(254,250,224, 0.3)",
        border: "#EAF3FA",
        shadow: "5px 5px 15px rgba(255, 255, 255, 0.5);"
      },
      musique: {
        primary: "#dfffe0",
        text: "#aad576",
        background: "rgba(20,54,1, 0.5)",
        border: "#75E216",
        shadow: "3px 3px 15px rgba(5, 252, 42, 0.3), inset 15px 15px 50px rgba(25, 219, 18, 0.3);"
      }
    },
    itStatus: {
      Stacks: {
        window: true,
        fullScreen: false,
        folder: {
          Plan: ["Figma", "Jenkins", "Trello"],
          Build: ["GitHub", "NodeJs", "PrismaORM", "TypeORM", "MySQL", "PostgreSQL"],
          Integration: ["GitLab", "Sonarqube", "Selenium"],
          Deploy: ["Docker", "Kubernetes"],
          Operate: ["Ansible", "Terraform"],
          Monitoring: ["Grafana", "Aws Tools"],
        }
      },
      Cursus: {
        window: true,
        fullScreen: false,
        folder: {
          Experience: ["Accenture", "Entrepreunariat"],
          Formation: ["HumanBooster", "OpenClassroom"]
        }
      },
      Man: {
        window: false,
        fullScreen: false,
      },
      Language: {
        window: true,
        fullScreen: false,
        folder: {
          html: "Html",
          CSS: "CSS",
          javascript: "Javascript",
          typescript: "Typescript",
          python: "Python",
          bash: "Bash"
        }
      },
      Projects: {
        window: true,
        fullScreen: false,
        folder: {}
      }

    },
    pathIt: "~",
    currentLs: [],
    xpCommerce: {
      cci: {
        id: "cci",
        compagny: "CCI Paris",
        cp: "75010, Paris X",
        post: "Conseiller en Création d'entrepise",
        contract: "CDD",
        dateBegin: "2019",
        dateEnd: "2020",
        skills: [
          "Business Développement",
          "Gestion de projet",
          "Formalité entreprise",
          "Process administratif",
        ],
        description: "Déjà convaincu par l'entrepreunariat, j'ai eu l'occasion de travailler pour la Chambre du Commerce et de l'industrie. Cela m'a donnée l'opportunité d'acquérir les compténce néccessaire à la création et au dévellopement perrein d'une TPE ou PME. ",
        site: "https://www.cci-paris-idf.fr/fr#1",
        url: require("../assets/commerce/xp/cci.png"),
        alt: "Logo de la chambre du commerce et de l'industrie",
        focus: false,
      },
      nespresso: {
        id: "nespresso",
        compagny: "Nespresso",
        cp: "77127, Lieusaint Carré Sénart et 94390, Orly Aéroport",
        post: "Expert Café",
        contract: "CDI",
        dateBegin: "2017",
        dateEnd: "2019",
        skills: [
          "Caféologie",
          "Formation d'équipe",
          "Communication de luxe",
          "Normes hygiénes",
          "Vente en flux tendu",
          "Suivie des stocks",
        ],
        description: "C'est initialement situé à Orly dans la boutique Nespresso de l'aéroport que j'ai découvert la caféologie. Ma premiere mission de conseillé de vente ma permis d'acquérir des connaissance dans le secteur du café qui m'ont aussi permis d'évolué dans celui-ci. C'est pas la suite que j'ai pu occuper un post d'Expert café dans une boutique localisé au centre commercial de Lieusaint.",
        site: "https://www.nespresso.com/fr/fr/",
        url: require("../assets/commerce/xp/Nespresso_transparent.png"),
        alt: "Logo de Nespresso",
        focus: false,
      },
      alinea: {
        id: "alinea",
        compagny: "Alinéa",
        cp: "91700, Saint-Geneviève-des-Bois",
        post: "Conseillé de vente",
        contract: "CDI",
        dateBegin: "2015",
        dateEnd: "2016",
        skills: [
          "Gestion/Prévision des stocks",
          "Achets et contacts fournisseurs",
          "Vente B2C",
        ],
        description: "Grace à la coopetation d'un ami, j'ai pu travailler avec celui-ci, en quasi-autonomie à l'intérieur du rayon Luminaires. Notre missions consité à la garantie des stocks, au devellopement de la satisfaction clientelle ainsi qu'a l'accroissement du CHiffre d'affaire généré par notre secteur.",
        site: "https://www.alinea.com/",
        url: require("../assets/commerce/xp/alinea.png"),
        alt: "Logo de Alinéa",
        focus: false,
      },
      tissot: {
        id: "tissot",
        compagny: "Tissot SA",
        cp: "75010, Paris VI",
        post: "Business devellopeur",
        contract: "Alternance",
        dateBegin: "2013",
        dateEnd: "2015",
        skills: [
          "Vente B2B multicanaux",
          "Documentations interne",
          "Reunion fournisseur",
          "Vente et demonstration en Salon",
        ],
        description: "C'est au sein de cette entreprise que j'ai pu améloirer mes comptécences commerciales mais aussi m'initié à la documentation Juridique. En effet, la veille juridique immobillier permanante ainsi que sa vulgarisation auprés des clients étais des atous clé dans la réalisation de mes objectifs.",
        site: "https://www.tissot.fr/",
        url: require("../assets/commerce/xp/tissot.png"),
        alt: "Logo des Editions Tissot ",
        focus: false,
      },
      becloud: {
        id: "becloud",
        compagny: "Be-cloud",
        cp: "78180, Saint-Quentin en Yvelines",
        post: "Assistant commercial",
        dateBegin: "2012",
        dateEnd: "2012",
        skills: [
          "Prospection téléphonique",
          "Rendez-vous commercial",
          "Reporting des resultats",
          "Support tehcnique",
        ],
        description: "C'est aupres de Lenny Vercruisse que j'ai eu l'occasion de goûter au monde commercial. Me laissant la chance de réaliser ma premiere expérience et misant sur l'autonomie comme source d'apprentissage. J'ai eu plaisir à parfaire mon discour commercial durant ces deux mois de stage chez Be-cloud.",
        site: "https://www.be-cloud.fr/",
        url: require("../assets/commerce/xp/becloud.png"),
        alt: "Logo de Be-cloud",
        focus: false,
      },
    },
    softSkills: {
      critique: {
        id: "critique",
        name: "Sens critique",
        icone: "fa-solid fa-arrow-trend-up",
        description: "Il est primordial de travailler son sens critique. Il s'agit d'un facteur clé dans l'amélioration et la périnisation de ses projets",
      },
      relational: {
        id: "relational",
        name: "Intelligence relationnel",
        icone: "fa-solid fa-comments",
        description: "Nous sommes améner à collaborer sur différentes thémtiques tous les jours, c'est pourquoi je trouve que l'empathie et l'écoute vitale dans l'aspect interprofessionnel de nos métiers"
      },
      flexibility: {
        id: "flexibility",
        name: "Fléxibilité",
        icone: "fa-solid fa-dice-d6",
        description: "Savoir se diversifier et s'adapter à l'évolution de nos techonologie deviens de plus en plus primordiale. Le monde avance, mais c'est à nous de lui montrons le chemin."
      },
      creativity: {
        id: "creativity",
        name: "Créativité",
        icone: "fa-solid fa-lightbulb",
        description: "C'est pour moi la créativité dans nos projets qui contribue à transformer le travail en jeu. J'aime pensé qu'une solution alternative existe à tous problémes."
      },
      decision: {
        id: "decision",
        name: "Prise de décision",
        icone: "fa-solid fa-comment-dots",
        description: "Prendre une décision n'est pas annodin. L'analyse néccaissers, la conscience des conséquences possibles ainsi que l'audace des enjeux liés sont inhérente à celle-ci."
      },
    },
  },
  getters: {},
  mutations: {
    changeActualTheme(state, newTheme) {
      if (!newTheme) {
        state.actualTheme = state.colors["accueil"]
      } else {
        state.actualTheme = state.colors[newTheme]
      }
    },
    changeStatusWindow(state, status) {
      let script = status.script
      let argument = status.argument
      let changeEtat = false
      for (const i in state.itStatus) {
        if (i == argument) {
          changeEtat = true
        }
      }
      if (changeEtat) {
        if (script == "open") {
          state.itStatus[argument].window = true
        } else if (script == "close") {
          state.itStatus[argument].window = false
        } else {
          console.log("Can't open the windows, please reload")
        }
      } else {
        console.log("dont recognize the commande")
      }
    },
    changeStatusItFullScreen(state, argument) {
      let changeEtat = false
      for (const i in state.itStatus) {
        if (i == argument) {
          changeEtat = true
        }
      }
      if (changeEtat) {
        if (state.itStatus[argument].fullScreen) {
          state.itStatus[argument].fullScreen = false
        } else if (!state.itStatus[argument].fullScreen) {
          state.itStatus[argument].fullScreen = true
        } else {
          console.log("Can't resize the windows, please reload")
        }
      }
    },
    changePath(state, argument) {
      let path = state.pathIt
      let allArgument = argument.split("/")
      let i = 0
      while (i < allArgument.length) {
        switch (allArgument[i]) {
          case "..": {
            if (path == "Home/") {
              console.log("It's cold outside")
            } else if (path == "~") {
              path = "Home/"
            } else if (path.match(/^[~]\/[a-zA-Z]/).input) {
              let myPath = path.split("/")
              switch (myPath.length) {
                case 3: {
                  path = myPath[0] + "/" + myPath[1]
                  break
                }
                case 2: {
                  path = myPath[0]
                  break
                }
                default: {
                  console.log("can't navigate amiral")
                  break
                }
              }
            }
            break
          }
          case allArgument[i].match(/[a-zA-Z]{1,8}/).input: {
            const currentPath = state.pathIt
            const architecture = currentPath.split("/")
            const lastArchi = architecture[architecture.length - 1]
            if (architecture.length == 1 || path == "Home/") {
              if (path == "Home/") {
                for (const indexView in state.colors) {
                  if (allArgument[i] == indexView) {
                    if (allArgument[i] != "Informatique") {
                      let direction = allArgument[i].toLowerCase()
                      router.push({
                        path: direction,
                        replace: true
                      })
                    }
                    path = "~"
                  }
                }
              } else if (path == "~") {
                for (const index in state.itStatus) {
                  if (allArgument[0] == index) {
                    path += "/" + allArgument[i]
                  }
                }
              }
            } else if (architecture.length == 2) {
              if (state.itStatus[lastArchi].folder) {
                for (const indexFolder in state.itStatus[lastArchi].folder) {
                  if (allArgument[i] == indexFolder)
                    path += "/" + allArgument[i]
                }
              } else {
                console.log("bad entry on itStatus folder")
              }
            }
            break
          }
        }
        i++
      }
      state.pathIt = path
    },
    getLs(state) {
      const Path = state.pathIt
      const PathMap = Path.split("/")
      if (PathMap[0] == "Home") {
        console.log("je passe")
        state.currentLs = []
        for (const i in state.colors) {
          state.currentLs.push(i)
        }
        return
      } else if (PathMap.length == 1) {
        state.currentLs = []
        for (const i in state.itStatus) {
          state.currentLs.push(i)
        }
        return
      } else if (PathMap.length == 2) {
        const lastElementOfPath = PathMap[PathMap.length - 1]
        console.log(lastElementOfPath)
        if (state.itStatus[lastElementOfPath].folder) {
          console.log("i'm in")
          state.currentLs = []
          for (const i in state.itStatus[lastElementOfPath].folder) {
            state.currentLs.push(i)
          }
          return
        }
      } else if (PathMap.length == 3) {
        const lastElementOfPath = PathMap[PathMap.length - 1]
        const folder = PathMap[PathMap.length - 2]
        const objectFocus = state.itStatus[folder].folder[lastElementOfPath]
        state.currentLs = []
        for (let i = 0; i < objectFocus.length; i++) {
          state.currentLs.push(objectFocus[i])
        }
      }
      return "Can't find the path"
    },
    changeCommerceXpCard(state, id) {
      state.xpCommerce[id].focus = true
    },
    resetCommerceShow(state) {
      for (const i in state.xpCommerce) {
        state.xpCommerce[i].focus = false
      }
    }
  },
  actions: {},
  modules: {}
})