<template>
  <div id="home">
    <div class="home_title">
      <h2>Jeremy Tardini</h2>
    </div>
    <div class="home_photo">
      <img
        class="home_photo_element"
        src="../assets/jeje.jpg"
        alt="Photo de Jeremy Tardini"
      />
    </div>
    <div class="home_profil">
      <p class="home_profil_description">
        Salut, je m'appelle Jeremy Tardini et je suis passionné par
        l'informatique, le commerce, les voyages et la musique. Je crois qu'il
        est important de s'ouvrir à de nouvelles expériences et de ne pas se
        limiter à une seule activité. Découvrir de nouvelles choses et
        apprendre continuellement est essentiel à mon goût. 
        <br><br>Je vous présente mon portfolio qui reflète ma
        diversité de compétences et mes expériences dans différents domaines. Je
        suis convaincu que ces qualifications variées me permettront d'aborder les
        défis de manière créative et de proposer des solutions innovantes aux projets que nous
        aurons en commun.
      </p>
    </div>
    <div id="travelCard" class="home_travel">
      <h3>Mes voyages</h3>
      <travelComponent v-show="travelComponentShow"></travelComponent>
      <img
        src="../assets/travel/landscape.png"
        alt="image de montagne en lignes noirs"
      />
      <p class="hoverMe" v-if="!travelComponentShow">Hover me</p>
    </div>
  </div>
</template>

<script>
import travelComponent from "../components/acceuil/travelComponent.vue";

export default {
  name: "HomeView",
  components: {
    travelComponent,
  },
  data() {
    return {
      mouvAnimation: false,
      travelComponentShow: false,
      home_child: null,

      groupOfDomElement: null,
    };
  },
  methods: {
    displayTravel() {
      this.travelComponentShow = true;
      this.displayElementHome("out");
    },
    outTravel() {
      this.travelComponentShow = false;
      this.displayElementHome("in");
    },
    displayElementHome(status) {
      if (status == "in") {
        this.home_child.forEach((element) => {
          if (element.id != "travelCard") {
            element.style.opacity = 1;
          }
        });
      } else if (status == "out") {
        this.home_child.forEach((element) => {
          if (element.id != "travelCard") {
            element.style.opacity = 0;
          }
        });
      } else {
        console.log("Bad entry, please reload the page ! ");
      }
    },
  },
  mounted() {
    let home = document.getElementById("home");
    this.home_child = home.childNodes;
    let travelCard = document.getElementById("travelCard");
    travelCard.addEventListener("mouseenter", this.displayTravel);
    travelCard.addEventListener("mouseleave", this.outTravel);
  },
};
</script>

<style lang="scss" scoped>
#home {
  transition: 0.2s;
  .home_title {
    @include cadreHome;
    animation: float2 10s ease infinite;
    background-color: rgba(254, 250, 224, 0.3);
    border: 1px solid rgba(254, 250, 224, 0.8);
    top: 14%;
    left: 10%;
    text-align: center;
    font-family: "philosopher", serif;
    font-size: 1.8em;

    z-index: 5;
    transition: 1s;
  }
  .home_photo {
    animation: float1 10s ease infinite;
    @include cadreHome;
    min-width: 300px;
    max-width: 700px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(254, 250, 224, 0.8);
    background-color: rgba(254, 250, 224, 0.3);
    transition: 1s;
    &_element {
      border-radius: 25px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .home_profil {
    animation: float2 10s ease infinite;
    @include cadreHome;
    z-index: 5;
    background-color: rgba(254, 250, 224, 0.3);
    border: 1px solid rgba(254, 250, 224, 0.8);
    height: 175px;
    min-width: 300px;
    overflow-y: scroll;
    text-align: justify;
    transition: 0.5s;
    &_description {
      font-size: 1.2em;
      font-family: "Caviar", sans-serif;
      font-weight: 600;
      color: $AcceuilText
    }
  }
  .home_profil::-webkit-scrollbar {
    width: 5px;
  }
  .home_profil::-webkit-scrollbar-track {
    color: transparent;
    margin: 20px 0 20px 0;
  }
  .home_profil::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 25px;
  }
  .home_travel {
    width: 30%;
    @include cadreHome;
    background-color: rgba(254, 250, 224, 0.3);
    border: 1px solid rgba(254, 250, 224, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: 0.5s;
    animation: float1 10s ease infinite;
    img {
      width: 90%;
    }
    h3 {
      font-family: "philosopher";
      font-size: 1.5em;
    }
    .hoverMe {
      font-family: "philosopher";
    }
  }
  .home_travel:hover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background-color: transparent;
    backdrop-filter: blur(15px);
    padding: 0;
  }
  .home_travel:hover > h3 {
    font-size: 2em;
  }
  .home_travel:hover > img {
    width: 30%;
  }
}

@media (max-width: 679px) {
  .home_title {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 95% !important;
    margin: 75px auto 10px auto !important;
    padding: 15px 0 15px 0 !important;
  }
  .home_photo {
    position: relative !important;
    width: 50%;
    margin: 10px auto 10px auto !important;
    border-radius: 150px !important;
    img {
      border-radius: 150px !important;
    }
  }
  .home_profil {
    position: relative !important;
    margin: 10px auto 10px auto !important;
  }
  .home_travel {
    position: relative !important;
    width: 90% !important;
    margin: auto !important;
  }
  .home_travel:hover {
    position: fixed !important;
    width: 100% !important;
  }
  .home_travel:hover h3 {
    padding-top: 50px !important;
  }
}

@media (min-width: 680px) and (max-width: 1024px) {
  .home_photo {
    top: 20%;
    left: 25%;
    width: 35%;
  }
  .home_profil {
    width: 40%;
    top: 45%;
    left: 45%;
  }
  .home_travel {
    top: 65%;
    left: 18%;
  }
  .home_travel:hover h3 {
    padding-top: 50px !important;
  }
}

@media (min-width: 1025px) {
  .home_photo {
    top: 20%;
    left: 25%;
    width: 25%;
  }
  .home_profil {
    width: 40%;
    top: 45%;
    left: 45%;
  }
  .home_travel {
    top: 65%;
    left: 18%;
  }
}
</style>