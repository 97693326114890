<template>
  <div id="man">
    <h3>Welcome in Manual</h3>
    <p class="man_intro">
      You can read all commands avalable here ! Dont hesite to scroll the
      windows. When i got time i like to put some features. Care i'm sensible a la casse !
    </p>
    <ul v-for="item in manRules" :key="item" class="man_list">
      <li>
        <h4>-- {{ item.command }} --</h4>
        <p>{{ item.description }}</p>
      </li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "manIt",
  data() {
    return {
      manRules: {
        man: {
          command: "man",
          description:
            "Famous Unix command allowing you to see all the features available on the site. For the moment the specificity isn't available. You can't use <Command> Man yet",
        },
        ls: {
          command: "ls",
          description:
            "This feature allows you to know the folders of the current directory. --- Exemple 'ls' ---",
        },
        cd : {
            command : "cd",
            description: "With this feature you can move directly to the folders of this section or go to another section of the site. Use the directory name after the 'cd' command to move. --- Exemple 'cd <Directory>' ---"
        },
        open: {
          command: "open",
          description:
            "You can use 'open' to open a currently close window. --- Exemple 'open <Directory>' ---",
        },
        close: {
          command: "close",
          description:
            "You can use 'close' to close a currently open window. --- Exemple 'close <Directory>' ---",
        },
        clear: {
          command: "clear",
          description:
            "Simply use the 'clear' feature to erase your current terminal. --- Exemple 'clear' ---",
        },
        resize : {
          command: "resize",
          description: "This command is used to enlarge and reduce a window. You must follow the resize command with the Name of the File to enlarge or reduce. The window must be open for the command to work. --- Example 'resize <Folder>' ---"
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
#man {
  overflow: scroll;
  overflow-x: hidden;
  height: 200px;
  list-style: none;
  padding: 15px 15px 0 15px;
  h3,
  h4 {
    color: $InformatiquePrimary;
    margin: 0 0 5px 0;
  }
  .man_intro {
    color: $InformatiqueText;
    font-size: 1.1em;
    margin: 0 0 10px 0;
  }
  .man_list {
    list-style: none;
    h4 {
      padding: 0 0 0 10px;
      font-size: 1.2em;
    }
    p {
      color: $InformatiqueText;
      margin: 5px;
    }
  }
}
#man::-webkit-scrollbar {
    width: 3px;
  }
#man::-webkit-scrollbar-track {
    color: transparent;
  }
#man::-webkit-scrollbar-thumb {
    background-color: rgba(117,226,22,0.5);
    border-radius: 25px;
    opacity: 0.2;
  }
</style>