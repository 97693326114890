<template>
    <div id="MyCursusIt">
        <div class="MyCursusIt_intro">
            <h2>Mon Cursus</h2>
            <p>Vous pourrez trouver ici toutes mes formations et expériences en rapport avec le monde de l'IT en régle générale</p>
        </div>
        <div class="MyCursusIt_separator"></div>
        <div class="MyCursusIt">
            <div class="MyCursusIt_exp">
                <h3>Expériences</h3>
                <div v-for="exp in exps" :key="exp" class="MyCursusIt_exp_containt">
                    <div class="MyCursusIt_exp_containt_informations">
                        <h4>{{ exp.name }}</h4>
                        <h5> {{ exp.post}} - {{ exp.type }}</h5>
                        <div class="MyCursusIt_exp_containt_informations_date">
                            <p>Début : {{ exp.begining }} </p>
                            <p v-if="exp.end">Fin - {{ exp.end }} </p>
                        </div>
                        <p class="MyCursusIt_exp_containt_informations_description"> {{ exp.description }} </p>
                    </div>
                    <img :src="exp.src" :alt="exp.alt" class="MyCursusIt_exp_containt_img">
                </div>
            </div>
            <div class="MyCursusIt_separator"></div>
            <div class="MyCursusIt_formation">
                <h3>Formations</h3>
                <div v-for="formation in formations" :key="formation" class="MyCursusIt_formation_containt">
                    <img :src="formation.src" :alt="formation.alt">
                    <div class="MyCursusIt_formation_containt_informations">
                        <h4>{{ formation.name }}</h4>
                        <p> {{ formation.date }} </p>
                        <p class="MyCursusIt_formation_containt_informations_description">{{ formation.description }}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"cursusComponent",
    data() {
        return {
            formations: {
                oc : {
                    name: "OpenClassroom",
                    src: require("../../assets/It/logoCursus/OpenClassroom.png"),
                    alt: "Logo OpenClassroom ",
                    date : "Du 01/07/2021 au 01/12/2021",
                    description : "Ayant un attrait naturel pour les nouvelles technologies, j'ai initialement découvert OpenClassroom grace a certain des c'est tutoriels gratuit. C'est par la suite que le format ainsi que la qualité du contenue de ce site on fini de me convaincre de me lancer dans une reconversation professionelle."
                },
                hb: {
                    name: "HumanBooster",
                    src: require("../../assets/It/logoCursus/humanbooster.png"),
                    alt: "Logo Humanbboster",
                    date: "Du 27/10/2022 au 27/01/2023",
                    description: "C'est apres avoir parcourue le monde du develloppement web que je me suis rendu compte de l'importance du secteur Opérationel dans la gestion de projets informatiques. Pour pourvoir obtenir ces comptétences je me suis lancé dans une formation complémentaire chez HumanBooster."
                }
            },
            exps: {
                accenture: {
                    name: "Accenture",
                    post: "DevOps",
                    type: "CDI",
                    begining: "06/02/2023",
                    end: null,
                    src: require("../../assets/It/logoCursus/accenture.png"),
                    alt: "Logo Acenture",
                    description: "Accenture est ma premiere expérience professioenlle dans le monde des DevOps. En mission chez Michelin, mon but est d'organiser les process industriels actuels en les redants plus performants via l'automatisation et autre techniques permettant la mise en place d'une gestion de projet Agile."
                },
                freelance: {
                    name: "Sayadini - Freelance",
                    post: "Devellopeur Web",
                    type: "Micro-Entreprise",
                    begining: "01/03/2022",
                    end: null,
                    src: require("../../assets/It/logoCursus/Sayadini.png"),
                    alt: "Logo Acenture",
                    description: "C'est suite à l'obtention de mon diplome de niveau 4 dans le develloppement web qua je me suis lancé dans l'aventure entreuprenariale. Liant mes comptétences dans la promotion de nouveau projet, je design aujourd'hui des site web via des technologie adaptées au dessin des futurs dirigeant d'entreprise."
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
#MyCursusIt {
    font-family: 'Ubuntu';
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    height: 65vh;
    .MyCursusIt_separator {
        width: 80%;
        height: 3px;
        background-color: $InformatiquePrimary;
        margin: 25px auto 0 auto;
        border-radius: 25px;
    }
    p {
        color: $InformatiqueText

    }
    .MyCursusIt_intro {
        width: 80%;
        margin: 25px auto 25px auto;
        text-align: center;
    }
    .MyCursusIt {
        display: flex;
        flex-wrap: wrap;
        h3 {
            font-size: 1.5em;
            text-align: center;
        }
        &_exp {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 80%;
            border-radius: 25px;
            margin: 0 auto 0 auto;
            &_containt {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin: 25px;
                &_informations {
                    width: 50%;
                    text-align: center;
                    &_description {
                        text-align: justify;
                    }
                    h4 {
                        font-size: 1.2em;
                    }
                    h5 {
                        font-size: 1em;
                    }
                    p {
                        margin: 5px 0 5px 0;
                    }
                }
                &_img {
                    width: 30%;
                    display: flex;
                    align-items: center;
                }

            }
        }
        &_formation {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: auto;
            flex-direction: column;
            margin: 25px auto 25px auto;
            &_containt {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                margin: 25px;
                img {
                    width: 30%;
                }
                &_informations {
                    width: 50%;
                    text-align: center;
                    h4 {
                        font-size: 1.2em;
                    }
                    p {
                        margin: 5px 0 5px 0;
                    }
                    &_description {
                        text-align: justify;
                    }
                }
            }
        }
    }

}
#MyCursusIt::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}
#MyCursusIt::-webkit-scrollbar-track {
    background-color: transparent;
}
#MyCursusIt::-webkit-scrollbar-thumb {
    background-color: $InformatiqueBackgroundRGBA;
}
</style>