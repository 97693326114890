import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
    faArrowRight,
    faArrowLeft,
    faPhone,
    faEnvelope,
    faWindowRestore,
    faBuildingColumns,
    faLanguage,
    faFolderOpen,
    faFolderTree,
    faFolder,
    faGlobe,
    faHouse,
    faHandshake,
    faCoffee,
    faUser,
    faScroll,
    faBriefcase,
    faListCheck,
    faXmark,
    faLightbulb,
    faCommentDots,
    faComments,
    faDiceD6,
    faArrowTrendUp,
    faPause,
    faPlay,
    faStop,
    faVolumeOff,
    faVolumeHigh,
    faUpload,
    faQuestion,

} from '@fortawesome/free-solid-svg-icons'
import {
    faGithub,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import {

} from "@fortawesome/free-regular-svg-icons"

library.add(faArrowLeft, 
    faArrowRight, 
    faEnvelope, 
    faGithub, 
    faLinkedin, 
    faPhone, 
    faWindowRestore, 
    faBuildingColumns, 
    faLanguage, 
    faFolderOpen, 
    faFolderTree, 
    faFolder,
    faGlobe,
    faHouse,
    faHandshake,
    faCoffee,
    faUser,
    faScroll,
    faBriefcase,
    faListCheck,
    faXmark,
    faLightbulb,
    faCommentDots,
    faComments,
    faDiceD6,
    faArrowTrendUp,
    faPause,
    faPlay,
    faStop,
    faVolumeOff,
    faVolumeHigh,
    faUpload,
    faQuestion,
)

createApp(App)
    .use(store)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')