<template>
  <div id="roueCommerce">
    <div class="Commerce_presentation">
      <h3>Ces secteurs qui transformés mon goût pour le commerce</h3>
      <p>
        Vous pourrait observer, comprendre des secteur qui me sont chere des
        lesquelles j'ai eu l'occasion de travailler. Suivez-moi au seins de ces
        différents univers.
      </p>
    </div>
    <div class="Commerce_vignettes">
      <div v-for="card in cards" :key="card" class="Commerce_vignettes_card">
        <div class="Commerce_vignettes_card_icones">
          <font-awesome-icon
            :icon="card.icone"
            class="Commerce_vignettes_card_icones_icone"
          />
          <div class="Commerce_vignettes_card_icones_iconeback">
            <font-awesome-icon
              :icon="card.icone"
              class="Commerce_vignettes_card_icones_iconeback_icone"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "roueCommerce",
  data() {
    return {
      innerWidth: window.innerWidth,
      myscrollY: 0,
      showCards: false,
      cards: {
        CCI: {
          name: "Entrepreunarial",
          description:
            "Secteur dynamique en constante évolution, j'ai toujours apprécier le coté flexible de l'entrepreunariat. Dans cette section je souhaite vous partager mes projets ainsi que mon expérience dans le montage d'entreprise.",
          icone: "fa-solid fa-handshake",
          alt: "Vignette card entrepreunariale",
        },
        Nespresso: {
          name: "Café",
          description:
            "Le café est trés certainement ma boisson chaude préferé.. J'ai eu la chance de parcourir ce secteur riche en savoir faire. Je vous enméne dans cette section à travers les caféicultures et le fin savoir de la caféologie.",
          icone: "fas-solid fa-coffee",
          alt: "Vignette card cafée",
        },
        Immobillier: {
          name: "Immobilier",
          description:
            "Il est possible que l'immobilier soit l'un des secteur dans lequel j'ai pu évoluer le plus longtemps. Fort des différents métiers que j'ai pu représenter je vous livre mon expertise dans cette setion.",
          icone: "fa-solid fa-house",
          alt: "Vignette card immobilier",
        },
      },
    };
  },
  methods: {},
  mounted() {
    gsap.fromTo("#roueCommerce", { x: "100vw", opacity: 0}, {x: "-40px", duration: 0.6, opacity: 1})
    gsap.to("#roueCommerce", {x: 0, duration:0.2, delay: 0.6})
    gsap.to(".Commerce_vignettes", {
      rotateZ: 360,
      duration: 50,
      repeat: -1,
      ease: "none",
    });
    gsap.to(".Commerce_vignettes_card", {
      rotateZ: -360,
      duration: 50,
      repeat: -1,
      ease: "none",
    });
  },
};
</script>

<style lang="scss" scoped>
#roueCommerce {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
    .Commerce_presentation {
      width: 50%;
      min-width: 300px;
      font-family: "Comfortaa";
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h3 {
        color: $CommercePrimary;
        font-size: 1em;
      }
      p {
        font-size: 0.6em;
        text-align: justify;
        margin: 15px;
      }
    }
  .Commerce_vignettes {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    &_card {
      width: 75px;
      height: 75px;
      @include backgroundCommerce;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 10px;
      min-width: 50px;
      border-radius: 25px;
      transition: 0.3s;
      &_icones {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        &_icone {
          position: relative;
          z-index: 9;
          left: 10%;
          border-radius: 15px;
          color: rgba(254, 250, 224, 0.45);
          width: 60%;
          height: 100%;
        }
        &_iconeback {
          position: relative;
          right: 10%;
          width: 40%;
          &_icone {
            color: $CommerceText;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>