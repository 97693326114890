<template>
  <div id="cursusComponent">
    <div class="Formation">
      <h2 style="text-align: left;">Scolaire</h2>
      <div v-for="item in scolaire" :key="item" class="Formation_item">
        <div class="Formation_item_containt reverse">
          <a :href="item.site" target="_blank" rel="noopener noreferrer"
            ><img :src="item.url" :alt="item.alt"
          /></a>
          <div class="Formation_item_containt_infos">
            <h3>{{ item.diploma }}</h3>
            <div class="Formation_item_containt_infos_info">
              <h4>
                {{ item.school }}
                <span> ({{ item.dateBegin }} - {{ item.dateEnd }})</span>
              </h4>
            </div>
            <p class="Formation_item_containt_infos_description">
              {{ item.description }}
            </p>
          </div>
        </div>
        <div class="tinySeparator"></div>
      </div>
    </div>
    <div class="separator"></div>
    <div Class="Formation">
      <h2 style="text-align: right;">Professionelle</h2>
      <div v-for="el in pro" :key="el" class="Formation_item">
        <div class="Formation_item_containt noreverse">
          <div class="Formation_item_containt_infos">
            <h3>{{ el.intitule }}</h3>
            <div class="Formation_item_containt_infos_info">
              <h4>
                {{ el.organisme }}<span> {{ el.date }}</span>
              </h4>
            </div>
            <p class="Formation_item_containt_infos_description">
              {{ el.description }}
            </p>
          </div>
          <a :href="el.site" target="_blank" rel="noopener noreferrer"
            ><img :src="el.url" :alt="el.alt"
          /></a>
        </div>
        <div class="tinySeparator"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "cursusComponent",
  data() {
    return {
      scolaire: {
        bac: {
          dateBegin: "2011",
          dateEnd: "2012",
          diploma: "Baccalauréat Science et Technique de Gestion",
          description:
            "Formation avec un option Ressources Humaines, j'ai commencé mon apprentissage avec les régles et principe appliqué a la communication et la gestion des ressources humaines.",
          school: "Lycée des Loges, 91000 Evry",
          url: require("../../assets/commerce/School/ParcDesLoges.png"),
          alt: "Logo Lycée du Parc des Loges",
          site: "http://www.lyc-parc-evry.ac-versailles.fr/",
        },
        muc: {
          dateBegin: "2012",
          dateEnd: "2013",
          diploma: "Management des Unitées Commerciales",
          description:
            "Cette formation m'a permis d'approfondir mes connaissances dans la gestion d'une boutique et des process liée à l'activité en grandes distributions et B2C.",
          school: "Médicis Alternance, 75013 Paris XIII",
          url: require("../../assets/commerce/School/Medicis.png"),
          alt: "Logo de l'école Médicis Alternance",
          site: "https://groupemedicis.com/",
        },
        nrc: {
          dateBegin: "2013",
          dateEnd: "2015",
          diploma: "Négociation Relation client",
          description:
            "Aprés avoir pu découvrir le coté B2C grace à une précedante formation, je me suis intéressé au secteur B2B et ces différents process. Ce cursus ma permis de conforté mon appétence dans les relation profféssionel inter-entreprise. ",
          school: "Ascensia Business School, 91000 Evry",
          url: require("../../assets/commerce/School/Ascencia.png"),
          alt: "Logo de l'école Ascencia Business School",
          site: "https://www.ascencia-business-school.com/",
        },
        achat: {
          dateBegin: "2016",
          dateEnd: "2017",
          diploma: "Licence Achat",
          description:
            "Toujours dans le but d'apprinfondir mes connaissance dans le domaine du commerce, j'ai suivie une formation d'achat afin de découvrir les spécificité lié au relation inter-entreprise dans les actvitées d'achats. ",
          school: "Pôle Paris Alternance, 75014 Paris XIV",
          url: require("../../assets/commerce/School/PPA.png"),
          alt: "Logo de l'école Pôle Paris Alternance",
          site: "https://www.ppa.fr/ecole-commerce-alternance.html",
        },
      },
      pro: {
        kestio: {
          date: "Juin 2013",
          intitule: "Méthode de vente IXOS",
          organisme: "Kestio",
          description:
            "Formation de vente qui à pour but de valoriser la vente additionel passant par un canal téléphonique.",
          url: require("../../assets/commerce/School/kestio.png"),
          alt: "Logo de l'entreprise Kestio Performance",
          site: "https://www.kestio.com/",
        },
        nespresso: {
          date: "Fevrié 2018",
          intitule: "Ambassadeur de la marque Nespresso",
          organisme: "Nespresso",
          description:
            "Foramtion axée sur la relation clientel dans le milieu du luxe. Nous avons mis l'accent sur le savoir-être et le savoir-faire essentiel au maintient d'un service client de qualité.",
          url: require("../../assets/commerce/xp/Nespresso.png"),
          alt: "logo Nespresso",
          site: "https://www.nespresso.com/fr/fr/",
        },
        haccp: {
          date: "Juin 2019",
          intitule: "HACCP",
          organisme: "CCI",
          description:
            "Foramtion sur les points de controles à appliqué dans les activité de restauration. Ce titre est essentiel à la mise en place d'une activité liée à la restauration.",
          url: require("../../assets/commerce/School/HACCP.png"),
          alt: "Logo de la formation HACCP",
          site: "https://www.economie.gouv.fr/dgccrf/hygiene-alimentaire-plan-maitrise-sanitaire-prerequis-et-lhaccp",
        },
      },
    };
  },
  mounted() {
    const divScolaire = document.querySelectorAll(".Formation");
    // const childs = divScolaire.childNodes;
    let alternator = false;
    let delay = 0;
    divScolaire.forEach((el) => {
      const childs = el.childNodes;
      childs.forEach((child) => {
        if (alternator) {
          gsap.from(child, {
            x: "100vw",
            duration: 0.5,
            delay: delay,
            ease: "elastic.out(0.5, 0.5)"
          });
          delay += 0.3;
          alternator = false;
        } else {
          gsap.from(child, {
            x: "-100vw",
            duration: 0.5,
            delay: delay,
            ease: "elastic.out(0.5, 0.5)"
          });
          delay += 0.3;
          alternator = true;
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
#cursusComponent {
  width: 100%;
  height: 100%;
  font-family: "Comfortaa";
  overflow-x: hidden;
  overflow-y: scroll;
  h2 {
    text-align: center;
    color: $CommercePrimary;
    font-size: 1.5em;
    margin: 50px;
  }
  .Formation {
    margin: 15px;
    &_item {
      &_containt {
        display: flex;
        justify-content: space-around;
        align-items: center;
        &_infos {
          display: flex;
          flex-direction: column;
          width: 65%;
          min-width: 320px;
          align-items: center;
          text-align: center;          
          &_info {
            font-size: 0.8em;
            display: flex;
            color: $CommerceText;
            margin: 10px;
            align-items: center;
            h4 {
              font-size: 0.8em;
              color: $CommerceText;
              span {
                margin-left: 10px;
                font-size: 0.6em;
              }
            }
          }
          &_description {
            font-size: 0.6em;
            margin: 30px 0 30px 0;
            text-align: justify;
          }
          h3 {
            margin: 10px;
            font-size: 0.9em;
            color: $CommercePrimary;
          }
        }
        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 25px;
        }
      }
      .tinySeparator {
        width: 50%;
        height: 1px;
        background-color: $CommerceBorder;
        margin: 25px auto;
      }
    }
  }
}
#cursusComponent::-webkit-scrollbar {
  width: 5px;
}
#cursusComponent::-webkit-scrollbar-track {
  background-color: transparent;
}
#cursusComponent::-webkit-scrollbar-thumb {
  background-color: $CommerceText;
}

.reverse {
    flex-wrap: wrap-reverse;
}
.noreverse {
    flex-wrap: wrap;
}
</style>