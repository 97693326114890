import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import InformatiqueView from '../views/InformatiqueView.vue'
import CommerceView from '../views/CommerceView.vue'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: HomeView
  },
  {
    path: '/informatique',
    name: 'Informatique',
    component: InformatiqueView,
  },
  {
    path: '/commerce',
    name: "Commerce",
    component: CommerceView,
  },
  // {
  //   path: "/musique",
  //   name: 'Musique',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/MusiqueView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
