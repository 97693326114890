<template>
    <div id=#Language>
        <div class="Language_intro">
            <h2>Langages de programmation</h2>
        </div>
        <div class="Language">
            <div v-for="item in langages" :key="item" class="Language_card">
                <img :src="item.src" :alt="item.alt">
                <div class="Language_card_text">
                    <h3> {{ item.name }}</h3>
                    <div class="Language_card_text_barres">
                        <div class="Language_card_text_barres_first"></div>
                        <div v-for="n in item.lvl - 3" :key="n" class="Language_card_text_barres_middle"></div>
                        <div id="progress" :class="{ colorDisplay : displayColor }"></div>
                        <div v-for="n in 9 - item.lvl" :key="n" class="Language_card_text_barres_after"></div>
                        <div class="Language_card_text_barres_last"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "languageComponent",
    data() {
        return {
            displayColor: false,
            langages: {      
                css: {
                    name: "CSS",
                    lvl: 9,
                    src: require("../../assets/It/logoLangages/CSS.png"),
                    alt: "Logo CSS",
                },
                html: {
                    name: "HTML",
                    lvl: 8,
                    src: require("../../assets/It/logoLangages/html.png"),
                    alt: "Logo HTML",
                },
                javascript: {
                    name: "Javascript",
                    lvl: 8,
                    src: require("../../assets/It/logoLangages/Js.png"),
                    alt: "Logo Javascript",
                },
                typescript: {
                    name: "Typescript",
                    lvl: 7,
                    src: require("../../assets/It/logoLangages/ts.png"),
                    alt: "Logo Typescript",
                },
                sql: {
                    name: "SQL",
                    lvl: 7,
                    src: require("../../assets/It/logoLangages/SQL.png"),
                    alt: "Logo SQL",
                },
                shell: {
                    name: "Bash",
                    lvl: 5,
                    src: require("../../assets/It/logoLangages/Shell.png"),
                    alt: "Logo Shell",
                },
                python: {
                    name: "Python",
                    lvl: 5,
                    src: require("../../assets/It/logoLangages/Python.png"),
                    alt: "Logo Python",
                }

            }
        }
    },
    mounted() {
        setInterval(() => {
            if(this.displayColor) {
                this.displayColor = false;
            } else {
                this.displayColor = true
            }
        }, 700)
    }
}
</script>

<style lang="scss" scoped>
#Language {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Language_intro {
        h2 {
            text-align: center;
            margin: 25px 25px 0 25px;
            padding: 25px;
            font-family: 'Ubuntu';
            font-size: 2em;
            border-bottom: 1px Solid $InformatiqueBackgroundRGBA;
        }
    }
    .Language {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin: 25px auto 25px auto;
        overflow: scroll;
        overflow-x: hidden;
        height: 54vh;
        &_card {
            margin: 25px;
            padding: 10px;
            border: 1px solid $InformatiquePrimary;
            width: 20%;
            min-width: 300px;
            display: flex;
            justify-content: space-evenly;
            height: 100px;
            img {
                width: 100px;

            }
            &_text {
                width: 60%;
                display: flex;
                flex-direction: column;
                text-align: center;
                font-family: "Ubuntu";
                justify-content: space-evenly;
                h3 {
                    margin: 5px;
                }
                &_barres {
                    width: 100%;
                    display: flex;
                    &_first {
                        width: 10%;
                        height: 15px;
                        background-color: $InformatiquePrimary;
                        clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
                    }
                    &_middle {
                        width: 10%;
                        height: 15px;
                        background-color: $InformatiquePrimary;
                        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
                    }
                    &_after {
                        width: 10%;
                        height: 15px;
                        background-color: $InformatiqueText;
                        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);                        
                    }
                    &_last {
                        width: 10%;
                        height: 15px;
                        background-color: $InformatiqueText;
                        clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
                    }
                    #progress {
                        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
                        background-color: $InformatiqueText;
                        transition: 0.2s;
                        width: 10%;
                        height: 15px;
                    }
                    .colorDisplay {
                        background-color: $InformatiquePrimary!important;
                    }
                }
            }
        }
    }
    .Language::-webkit-scrollbar {
        width: 5px;
    }
    .Language::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .Language::-webkit-scrollbar-thumb {
        background-color: $InformatiqueBackgroundRGBA;
    }
}
</style>