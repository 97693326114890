<template>
  <div class="travelMovie">
    <div class="travelMovie_AllContenaire">
      <input
        class="travelMovie_AllContenaire_vignette"
        v-for="item in youtubeVideos"
        :key="item"
        :id="item.id"
        type="button"
        :value="item.title"
      >
    </div>
    <div class="travelMovie_contenair" id="iframe">
      <iframe
        title="My travel on youtube"
        width="500"
        height="300"
        :src="index.src"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="travelMovie_contenair_iframe"
      ></iframe>
      <div class="travelMovie_contenair_add">
        <h4>{{ index.title }}</h4>
        <div class="tiret"></div>
        <p>{{ index.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "travelComponent",
  data() {
    return {
      innerWidth: undefined,
      index: {
        id: "laugavegur",
        src: "https://www.youtube.com/embed/XM4yWdng-xs",
        title: "Trek du Laugavegur",
        description:
          "Trek de 55km dans le sud ouest de l'islande réalisé avec Julien Dusquesne, Maxime Pichot et Mélinda Sayasith",
      },
      youtubeVideos: {
        parisDam: {
          id: "parisDam",
          src: "https://www.youtube.com/embed/OutVTNjFxcs",
          title: "Paris - Amsterdam",
          description:
            "Voyage entre Paris et amsterdam à vélo. Accompagné par Maxime Pichot",
        },
        laugavegur: {
          id: "laugavegur",
          src: "https://www.youtube.com/embed/XM4yWdng-xs",
          title: "Trek du Laugavegur",
          description:
            "Trek de 55km dans le sud ouest de l'islande réalisé avec Julien Dusquesne, Maxime Pichot et Mélinda Sayasith",
        },
        gr30: {
          id: "gr30",
          src: "https://www.youtube.com/embed/d4PhlcMyhlw",
          title: "GR30 - Tour des lacs d'auvergne",
          description:
            "Réalisé avec Maxime Chlique, Coralie Germe et Melinda Sayasith. Trek de 180km autours des lac d'auvergne en passant par le Puy de Sancy",
        },
        tmb: {
          id: "tmb",
          src: "https://www.youtube.com/embed/FnMBIdVVvq0",
          title: "Tour du Mont Blanc",
          description:
            "Trek en famille autours du mont Blanc. Passage par le col des Arpettes",
        },
      },
    };
  },
  mounted() {
    this.innerWidth = window.innerWidth
    const myTravelList = document.querySelectorAll(
      ".travelMovie_AllContenaire_vignette"
    );
    const iframe = document.getElementById("iframe")
    myTravelList.forEach((el) => {
      el.addEventListener("click", () => {
        this.index = this.youtubeVideos[el.id];
        if(this.innerWidth >= 1200) {
        switch (el.id) {
          case "parisDam" :
            iframe.style.borderRadius = "0 25px 25px 25px" 
            break
          case "tmb" :
            iframe.style.borderRadius = "25px 25px 25px 0 "
            break
          default:
            iframe.style.borderRadius = "25px 25px 25px 25px "
            break
        }
        }

      });
    });
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.travelMovie {
  width: 95%;
  display: flex;
  justify-content: space-evenly;
  height: 60%;
  margin: 15px;
  &_contenair {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 50px;
    flex-wrap: wrap;
    background-color: rgba(254, 250, 224, 0.7);
    border: 1px solid rgba(254, 250, 224, 0.8);
    border-radius: 25px;
    transition: 0.2s;
    &_add {
      width: 100%;
      min-width: 300px;
      height: 70%;
      text-align: center;
      h4 {
        font-family: "philosopher", serif;
        font-size: 1.3em;
      }
      p {
        font-family: "Caviar", sans-serif;
      }
    }
    &_iframe {
      widows: 50%;
    }
  }
  &_AllContenaire {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &_vignette {
      width: 100%;
      text-align: center;
      background-color: transparent;
      border: none;
      transition: 0.2s;
      font-family: 'Caviar';
      font-weight: 600;
      font-size: 1.3em;
      height: 25%;
    }
    &_vignette:focus {
    width: 100%;
    background-color: rgba(254, 250, 224, 0.7);
    border-left: 1px solid rgba(254, 250, 224, 0.8);
    border-top: 1px solid rgba(254, 250, 224, 0.8);
    border-bottom: 1px solid rgba(254, 250, 224, 0.8);
    border-radius: 25px 0 0 25px ;
    }
    &_vignette::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: red;
        position: absolute;
      }
  }
}

@media (max-width: 1200px) {
  .travelMovie {
    flex-direction: column;
    align-items: center;
    &_AllContenaire {
      width: 100%;
      flex-direction: row!important;
      flex-wrap: wrap;
      justify-content: space-evenly;
      &_vignette {
        width: 200px;
        font-size: 0.8em;
      }
      &_vignette:focus {
        width: 25%;
        background-color: transparent;
        border: none;
      }
    }
  }
}
</style>