<template>
  <div id="xpComponent">
    <ul class="xp_containt">
      <li
        v-for="item in this.$store.state.xpCommerce"
        :key="item"
        class="xp_containt_card"
        :id="item.id"
      >
        <button v-on:click="displayInActivty" class="xp_containt_title_close" v-if="inFocus == item.id">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
        <div class="xp_containt_card_logo">
          <h2 v-if="this.inFocus == undefined">{{ item.compagny }}</h2>
          <img
            :src="item.url"
            :alt="item.alt"
            class="xp_containt_card_logo_img"
          />
        </div>
        <div v-if="inFocus == item.id" id="FocusCard">
          <div class="xp_containt_title">
            <a :href="item.site"
              ><h2>{{ item.compagny }}</h2></a
            >
            <div class="xp_containt_title_infos">
              <h3>{{ item.post }}</h3>
              <p>{{ item.dateBegin }} - {{ item.dateEnd }}</p>
              <p>{{ item.contract }}</p>
            </div>
            <p class="description">{{ item.description }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "XpComponent",
  data() {
    return {
      inFocus: undefined,
    };
  },
  methods: {
    async showActivity(e) {
      console.log(e.target);
      let el = e.target;
      while (el.getAttribute("class") != "xp_containt_card") {
        el = el.parentNode;
      }
      console.log(e);
      const id = el.getAttribute("id");
      await this.displayOutActivity(id);
      el.style.display = "flex";
      this.$store.commit("changeCommerceXpCard", id);
      this.animateEntryInformations(el);
    },
    async displayOutActivity(id) {
      this.inFocus = id;
      const divs = document.querySelectorAll(".xp_containt_card");
      divs.forEach((el) => {
        if (el.getAttribute("id") != id) {
          el.style.display = "none";
        }
      });
      const card = document.getElementById(id);
      card.style.width = "100%";
      card.childNodes[1].style.width = "20%";
    },
    async displayInActivty() {
      const divs = document.querySelectorAll(".xp_containt_card");
      divs.forEach((el) => {
        el.style.display = "block";
        if (el.getAttribute("id") == this.inFocus) {
          console.log(el);
          el.style.width = "7%";
          el.childNodes[1].style.width = "100%";
        }
      });
      this.inFocus = undefined;
      this.$store.commit("resetCommerceShow");
      await this.animateEntryActivitys();
    },
    async animateEntryActivitys() {
      const compagnys = document.querySelectorAll(".xp_containt_card_logo");
      let delay = 0;
      let interuptorApparition = false;
      compagnys.forEach((el) => {
        if (interuptorApparition) {
          gsap.from(el, {
            y: "-35vh",
            duration: 0.2,
            opacity: 0,
            delay: delay,
          });
          delay += 0.2;
          interuptorApparition = false;
        } else {
          gsap.from(el, { y: "35vh", duration: 0.2, opacity: 0, delay: delay });
          delay += 0.2;
          interuptorApparition = true;
        }
        el.addEventListener("click", this.showActivity);
      });
    },
    async animateEntryInformations(el) {
      el = el.childNodes;
      console.log(el);
      let delay = 0;
      let interuptor = false;
      el.forEach((el) => {
        if (interuptor) {
          gsap.from(el, {
            opacity: 0,
            x: "-100vw",
            duration: 0.4,
            delay: delay,
          });
          delay += 0.4;
        } else {
          gsap.from(el, {
            opacity: 0,
            x: "100vw",
            duration: 0.4,
            delay: delay,
          });
          delay += 0.4;
        }
      });
    },
  },
  mounted() {
    this.animateEntryActivitys();
  },
};
</script>

<style lang="scss" scoped>
#xpComponent {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  .xp_containt {
    display: flex;
    flex-wrap: wrap;
    font-family: "Comfortaa";
    justify-content: space-evenly;
    &_card {
      list-style: none;
      margin: 20px;
      width: 7%;
      min-width: 100px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &_logo {
        margin: auto;
        img {
          width: 100%;
          margin: 15px 0;
        }
      }

      h2 {
        font-size: 0.5em;
        text-align: center;
      }
    }
    &_title {
      width: 70%;
      transition: 0.4s;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      &_infos {
        opacity: 1;
        p {
          font-size: 0.6em;
          margin: 10px;
          text-align: center;
        }
      }
      h3 {
        color: $CommerceText;
        font-size: 1em;
      }
      a {
        font-size: 1.5em;
        text-decoration: none;
        color: $CommercePrimary;
      }
      .description {
        font-size: 0.6em;
        text-align: justify;
        margin: 15px;
      }
      &_close {
        font-size: 1em;
        position: absolute;
        top: 15px;
        left: 15px;
        color: $CommercePrimary;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}
#xpComponent::-webkit-scrollbar {
  width: 5px;
}
#xpComponent::-webkit-scrollbar-track {
  background-color: transparent;
}
#xpComponent::-webkit-scrollbar-thumb {
  background-color: $CommerceText;
}
</style>