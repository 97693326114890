<template>
  <div id="softSkills">
    <p class="descriptionSoftskill">
      J'ai pu realiser differents test de personnalité à travers ma carriere et
      voici les differents éléments qui en ressorte
    </p>
    <div class="listSkills">
      <button
        class="listSkills_skill"
        v-for="item in this.$store.state.softSkills"
        :key="item"
        :id="item.id"
      >
        <font-awesome-icon :icon="item.icone" />
        <h2>{{ item.name }}</h2>
      </button>
    </div>
    <p id="myText">{{actualText}}</p>
  </div>
</template>


<script>
import { gsap } from "gsap";

export default {
  name: "SoftskillComponent",
  data() {
    return {
      skillFocus: undefined,
      length: 0,
      actualText: "",
    };
  },
  methods: {
    async getTarget(e) {
      let el = e.target;
      while (!el.getAttribute("id")) {
        el = el.parentNode;
      }
      return el;
    },
    async replaceText(e) {
      const el = await this.getTarget(e);
      const id = el.getAttribute("id");
      this.actualText = this.$store.state.softSkills[id].description;
    },
    AnimationEntry() {
      let delay = 0
      gsap.from(".descriptionSoftskill", { x: "-50vw", duration: 0.2, ease: "ease", delay: delay, opacity:0})
      delay += 0.2
      gsap.from(".listSkills", {x : "50vw", duration: 0.2, ease: "ease", delay: delay, opacity:0})
      delay += 0.2
      const buttons = document.querySelectorAll('.listSkills_skill')
      buttons.forEach((el) => {
        gsap.from(el, {duration: 0.15, ease: "linear", rotateZ: "360deg", delay: delay})
        delay += 0.1
      })
      buttons.forEach((el) => {
        el.addEventListener('mouseover', this.replaceText)
      })
    },
  },
  mounted() {
    this.AnimationEntry()
  },
};
</script>

<style lang="scss" scoped>
#softSkills {
  width: 100%;
  height: 100%;
  min-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden ;
  font-family: "Comfortaa";
  justify-content: space-around;
  flex-wrap: wrap;
  .descriptionSoftskill {
    width: 80%;
    transition: 0.2s;
    color: $CommerceText;
    font-size: 1.3rem;
    margin: auto;
    text-align: center;
    opacity: 1;
  }
  .listSkills {
    color: $CommercePrimary;
    width: 100%;
    min-width: 250px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    &_skill {
      width: 100px;
      height: 100px;
      font-size: 1.5rem;
      cursor: pointer;
      transition: 0.2s;
      font-family: "Comfortaa";
      @include backgroundCommerce;
      color: $CommerceText;
      border-radius: 25px;
      opacity: 1;
      margin: 15px 0;
    }
    button:hover {
      color: $CommercePrimary;
      letter-spacing: 3px;
      width: 200px;
      z-index: 10;
    }
    button:active {
      color: $CommercePrimary;
    }
    h2 {
      font-size: 0.5em;
    }
  }
  #myText {
    font-size: 1.2rem;
    width: 85%;
    margin: 15px auto;
    text-align: justify;
    color: $CommerceText;
    font-style: italic;
  }
}
#softSkills::-webkit-scrollbar {
  width: 5px;
}
#softSkills::-webkit-scrollbar-track {
  background-color: transparent;
}
#softSkills::-webkit-scrollbar-thumb {
  background-color: $CommerceText;
}
</style>