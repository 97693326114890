<template>
  <div id="myapp">
    <div id="containt_background">
      <div id="filtreBackground"></div>
      <img id="background" src="./assets/background.jpg" alt="">
    </div>
    <navigationHeader @downIndex="downIndex" @upIndex="upIndex" :index="this.index"></navigationHeader>
    <div id="cadre">
      <router-view/>
    </div>
    <navigationSocialNetwork :index="this.index"></navigationSocialNetwork>
  </div>
</template>

<script>
import navigationHeader from "./components/navigationHeader.vue"
import navigationSocialNetwork from "./components/navigationSocialNetwork.vue"

export default {
  name:"app",
  components: {
    navigationHeader,
    navigationSocialNetwork,
  },
  data() {
    return {
      index: 0,
      nbrOfRoute: null,
      routes: this.$route
    }
  },
  methods: {
    upIndex() {
      if(this.index == this.nbrOfRoute -1) {
        this.index = 0
      } else {
        this.index++
      }
    },
    downIndex() {
      if(this.index == 0) {
        this.index = this.nbrOfRoute -1
      } else {
        this.index--
      }
    },
    changeColor() {
      let imgFilter = document.getElementById("filtreBackground")
      imgFilter.style.backgroundColor = this.$store.state.actualTheme.background
      let cadre = document.getElementById("cadre")
      cadre.style.backgroundColor = this.$store.state.actualTheme.background
      cadre.style.border = `1px ridge ${this.$store.state.actualTheme.border}`
    }
  },
  mounted() {
    const routes = this.$router.getRoutes()
    let compteur = 0
    for(const i in routes) {
      if(routes[i].path == this.$route.path) {
        this.index = compteur
        break
      }
      compteur++
    }
    // let makeIndex = 0
    routes.forEach(() => {
      this.nbrOfRoute++
      })
    const path = document.location.hash.split('#/')
    this.$store.commit("changeActualTheme", path[1])
    this.changeColor()
  },
  watch: {
    index(n) {
      const routes = this.$router.getRoutes()
      const theme = routes[n].path.split("/")
      this.$store.commit("changeActualTheme", theme[1])
      this.changeColor()
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "philosopher";
  src: url("./style/font/Philosopher-Regular.ttf")
}

@font-face {
  font-family: "Caviar";
  src: url("./style/font/CaviarDreams.ttf");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./style/font/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: "Comfortaa";
  src: url("./style/font/Comfortaa-Regular.ttf");
}

@font-face {
  font-family: "Funk";
  src: url("./style/font/LT_Funk.otf");
}

*{
  margin: 0;
  box-sizing: content-box;
  padding: 0;
}
body {
  width: 100%;
  height: 100%;
}

#myapp {
    width: 100%;
    height: 100%;
    #containt_background {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 1;
      #background {
        width: 100%;
        height: 100%;
        position: fixed;
        object-fit: cover;
        z-index: -10;
      }
      #filtreBackground {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -9;
      }     
    }
    #cadre {
      @include cadre;
      transition: 1s;
      background-color: $AcceuilBackground; 
      z-index: 2;
      overflow: hidden;
    }
}
@media (min-width: 1024px) {
  #cadre {
      top: 7%;
      bottom: 7%;
      left: 7%;
      right: 7%;
  }  
}

@media (min-width: 501px) and (max-width: 1023px) {
  #cadre {
      top: 4%;
      bottom: 4%;
      left: 4%;
      right: 4%;
  }
}

@media (max-width: 500px) {
  #cadre {
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    padding: 0!important;
  }
}
</style>
