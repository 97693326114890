<template>
  <div id="projectsCommerce">
    <div
      v-for="project in this.actualProject"
      :key="project"
      :id="project.id"
      class="project"
    >
      <img
        :src="project.url"
        :alt="project.alt"
        class="project_img"
        v-if="project.url"
      />
      <div class="separatorXp" v-if="project.url"></div>
      <div class="project_infos">
        <h2 id="titleProject">{{ project.name }}</h2>
        <p id="activityProject" class="activity">{{ project.activity }}</p>
        <p>{{ project.description }}</p>
      </div>
    </div>
    <div class="nav">
      <button
        class="nav_ButtonCommerce"
        id="previousNavCommerce"
        v-on:click="previousNavCommerce"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-left" />
      </button>
      <button
        class="nav_ButtonCommerce"
        id="nextNavCommerce"
        v-on:click="nextNavCommerce"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-right" />
      </button>
    </div>
  </div>
</template>


<script>
import { gsap } from "gsap";
export default {
  name: "ProjectComponent",
  data() {
    return {
      indexProject: 0,
      lenghtProjects: 0,
      actualProject: {
        project: {
          id: "sayadini",
          name: "Sayadini",
          activity: "Entreprise de creation de site Web",
          description:
            "A la suite de ma formation de le dévellopement web, je me suis lancé en tant qu'indépendant dans la creation de site Web sur mesure.",
        },
      },
      myProjects: {
        sayadini: {
          id: "sayadini",
          name: "Sayadini",
          activity: "Entreprise de creation de site Web",
          description:
            "A la suite de ma formation de le dévellopement web, je me suis lancé en tant qu'indépendant dans la creation de site Web sur mesure.",
        },
        selfcare: {
          id: "selfcare",
          name: "Selfcare Immobilier",
          activity: "Entreprise de coaching immobilier",
          description:
            "C'est aprés avoir étudier la marché immobilier francais que je me suis rendu compte qu'il etais possible de proposer un service différentes de celui apporté par les mandataires et agents classique. Mon but était d'accompagner les acheteurs et vendeur dans la realisation de la projets.",
          url: require("../../assets/commerce/projects/sci.png"),
          alt: "Logo SelfCare Immobilier",
        },
        rgtm: {
          id: "rgtm",
          name: "Rien gâcher, tous manger",
          activity: "Association de recyclage alimentaire",
          description:
            "C'est à la suite de ma formation sur les normes HACCP que j'ai entreprie de faire du recyclage alimentaires au pres des restaurateurs voisins.",
        },
      },
    };
  },
  methods: {
    disableComponent() {
      gsap.to(".project_infos", {
        x: "100vw",
        opacity: 0,
        duration: 0.5,
        delay: 0.3,
      });
      if (this.actualProject.url  === undefined) {
        gsap.to(".separatorXp", { y: "-100vh", opacity: 0, duration: 0.5 });
        gsap.to(".project_img", {
          x: "-100vw",
          opacity: 0,
          duration: 0.5,
          delay: 0.3,
        });
      }
    },
    enableComponent() {
      gsap.to(".project_infos", {
        x: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.3,
      });
      if (this.actualProject.url === undefined) {
        gsap.to(".separatorXp", { y: 0, opacity: 1, duration: 0.5 });
        gsap.to(".project_img", {
          x: 0,
          opacity: 1,
          duration: 0.5,
          delay: 0.3,
        });
      }
    },
    nextNavCommerce() {
      this.disableComponent();
      setTimeout(() => {
        if (this.indexProject == this.lenghtProjects - 1) {
          this.indexProject = 0;
        } else {
          this.indexProject++;
        }
      }, 1000)
      setTimeout(() => {
        this.enableComponent()
      }, 1000)
    },
    previousNavCommerce() {
      this.disableComponent();
      setTimeout(() => {
        if (this.indexProject == 0) {
          this.indexProject = this.lenghtProjects - 1;
        } else {
          this.indexProject--;
        }
      }, 1000);
      setTimeout(() => {
        this.enableComponent();
      }, 1000);
    },
    getLengthProjects() {
      for (const i in this.myProjects) {
        this.lenghtProjects++;
        i;
      }
    },
  },
  mounted() {
    this.getLengthProjects();
    document.querySelector(".project_infos h2").style.texteAlign = "center";
    this.enableComponent()
  },
  watch: {
    indexProject(n) {
      this.actualProject.project =
        this.myProjects[Object.keys(this.myProjects)[n]];
      const div = document.querySelector(".project_infos");
      gsap.from(div, { scale: 2, duration: 3 });
    },
  },
};
</script>

<style lang="scss" scoped>
#projectsCommerce {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Comfortaa", sans-serif;
  flex-direction: column;
  padding: 15px;
  .project {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    opacity: 1;
    &_infos {
      width: 65%;
      min-width: 300px;
      transform: translateX(100vw);
      p {
        font-size: 0.6em;
        text-align: justify;
        margin: 5px;
      }
      h2 {
        font-size: 1.2em;
        color: $CommercePrimary;
        text-align: center;
        margin: 10px;
      }
      .activity {
        font-size: 0.7em;
        font-weight: 800;
        text-align: center;
      }
    }
    &_infos::-webkit-scrollbar {
      width: 5px;
    }
    &_infos::-webkit-scrollbar-thumb {
      background-color: $CommerceText;
    }
    &_infos::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &_img {
      width: 20%;
      object-fit: cover;
      min-width: 150px;
      transform: translateX(-100vw);
    }
    .separatorXp {
      width: 3px;
      background-color: $CommercePrimary;
      height: 95%;
      margin: auto 15px;
      transform: translateY(-100vh);
    }
  }
  .nav {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
    margin: 15px 0 0 0;
    &_ButtonCommerce {
      z-index: 9;
      @include backgroundCommerce;
      height: 45%;
      border-radius: 25px;
      font-size: 1em;
      margin: 10px;
      color: $CommerceText;
    }
  }
}
</style>
