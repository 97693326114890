<template>
  <div id="Informatique">
    <div v-if="showInit" class="informatique_init" id="init1">
      <p>Systeme boot</p>
    </div>
    <div
      v-show="!showInit && this.$store.state.itStatus.Stacks.window"
      class="informatique_stacks windows"
      id="Stacks"
    >
      <div class="informatique_stacks_head windowHead">
        <button class="openButton">
          <font-awesome-icon icon="fa-solid fa-window-restore" />
        </button>
        <button class="closeButton">X</button>
      </div>
      <div class="informatique_stacks_miniature">
        <h2>Mes stacks</h2>
        <img
          v-show="!this.$store.state.itStatus.Stacks.fullScreen"
          src="../assets/It/RoueMiniatureDevOps.png"
          alt="miniature roue DevOps"
        />
        <stacksComponent
          v-show="this.$store.state.itStatus.Stacks.fullScreen"
        ></stacksComponent>
      </div>
    </div>
    <div
      v-show="!showInit && this.$store.state.itStatus.Cursus.window"
      class="informatique_cursus windows"
      id="Cursus"
    >
      <div class="informatique_cursus_head windowHead">
        <button class="openButton">
          <font-awesome-icon icon="fa-solid fa-window-restore" />
        </button>
        <button class="closeButton">X</button>
      </div>
      <div class="informatique_cursus_miniature">
        <div
          v-show="!this.$store.state.itStatus.Cursus.fullScreen"
          class="informatique_cursus_miniature_display"
        >
          <font-awesome-icon
            class="informatique_cursus_miniature_display_icone"
            icon="fa-solid fa-building-columns"
          />
          <div class="informatique_cursus_miniature_display_text">
            <h3>Mon cursus</h3>
            <p>...</p>
          </div>
        </div>
        <cursusComponent
          v-show="this.$store.state.itStatus.Cursus.fullScreen"
        ></cursusComponent>
      </div>
    </div>
    <div
      v-show="!showInit && this.$store.state.itStatus.Language.window"
      class="informatique_language windows"
      id="Language"
    >
      <div class="informatique_language_head windowHead">
        <button class="openButton">
          <font-awesome-icon icon="fa-solid fa-window-restore" />
        </button>
        <button class="closeButton">X</button>
      </div>
      <div class="informatique_language_miniature">
        <div
          v-show="!this.$store.state.itStatus.Language.fullScreen"
          class="informatique_language_miniature_display"
        >
          <div class="informatique_language_miniature_display_text">
            <h3>Mes langages</h3>
            <p>...</p>
          </div>
          <font-awesome-icon
            icon="fa-solid fa-language"
            class="informatique_language_miniature_display_icone"
          />
        </div>
        <languageComponent
          v-show="this.$store.state.itStatus.Language.fullScreen"
        ></languageComponent>
      </div>
    </div>


<div
      v-show="!showInit && this.$store.state.itStatus.Projects.window"
      class="informatique_projects windows"
      id="Projects"
    >
      <div class="informatique_projects_head windowHead">
        <button class="openButton">
          <font-awesome-icon icon="fa-solid fa-window-restore" />
        </button>
        <button class="closeButton">X</button>
      </div>
      <div class="informatique_projects_miniature">
        <div
          v-show="!this.$store.state.itStatus.Projects.fullScreen"
          class="informatique_projects_miniature_display"
        >
          <div class="informatique_projects_miniature_display_text">
            <h3>Mes Projets</h3>
            <p>...</p>
          </div>
          <font-awesome-icon
            icon="fas-solid fa-folder-tree"
            class="informatique_projects_miniature_display_icone"
          />
        </div>
        <projectsComponent
          v-show="this.$store.state.itStatus.Projects.fullScreen"
        ></projectsComponent>
      </div>
    </div>







    <div
      v-show="!showInit && this.$store.state.itStatus.Man.window"
      class="informatique_man windows"
      id="Man"
    >
      <div class="informatique_cursus_head windowHead">
        <button class="openButton">
          <font-awesome-icon icon="fa-solid fa-window-restore" />
        </button>
        <button class="closeButton">X</button>
      </div>
      <manIt></manIt>
    </div>
    <windowOrganisationIt @resize="openWindow"></windowOrganisationIt>
  </div>
</template>

<script>
import windowOrganisationIt from "../components/it/windowOrganisationIt.vue";
import manIt from "../components/it/manIt.vue";
import stacksComponent from "../components/it/stacksComponent.vue";
import cursusComponent from "../components/it/cursusComponent.vue";
import languageComponent from "../components/it/languageComponent.vue";
import projectsComponent from "../components/it/projectsComponent.vue"

export default {
  name: "informatiqueView",
  components: {
    windowOrganisationIt,
    manIt,
    stacksComponent,
    cursusComponent,
    languageComponent,
    projectsComponent,
  },
  data() {
    return {
      showInit: true,
      showStacks: false,
      elemntInDrag: undefined,
      position_stack: {
        pos1: 0,
        pos2: 0,
        pos3: 0,
        pos4: 0,
      },
    };
  },
  methods: {
    addPoint(node) {
      let child = node.childNodes;
      if (child.length <= 3) {
        const point = document.createElement("p");
        point.innerHTML += ".";
        node.append(point);
      } else {
        for (let i = 0; i < child.length - 1; i++)
          node.removeChild(node.lastChild);
      }
    },
    closeWindow(e) {
      this.$store.commit("changeStatusWindow", {
        script: "close",
        argument: e.srcElement.parentNode.parentNode.id,
      });
    },
    emitClick(e) {
      let button = e.target.parentNode
      let head = e.target
      while(!button.getAttribute("class")) {
        button = button.parentNode
      }
      while(!head.getAttribute("id")) {
        head = head.parentNode
      }
      this.openWindow(head.id, button);
    },
    openWindow(id, button) {
      if (!button) {
        const getButton = document.querySelector(`#${id} .openButton`);
        button = getButton;
      }
      for (const i in this.$store.state.itStatus) {
        if (i == id) {
          this.$store.commit("changeStatusItFullScreen", id);
          if (this.$store.state.itStatus[id].fullScreen) {
            this.applyClassToOpenWindow(id);
          } else {
            this.applyClassToCloseWindow(id);
            button.style.color = "black";
          }
        }
      }
    },
    openWindowCmd() {},
    applyClassToOpenWindow(element) {
      let div = document.getElementById(element);
      let divClass = div.getAttribute("class");
      div.setAttribute("class", `fullScreen ${divClass}`);
      let child = div.lastChild;
      let childClass = child.getAttribute("class");
      child.setAttribute("class", `fullScreenChildren ${childClass}`);
    },
    applyClassToCloseWindow(element) {
      let div = document.getElementById(element);
      let divClass = div.getAttribute("class");
      let tableOfClass = divClass.split(" ");
      for (let i = 0; i < tableOfClass.length; i++) {
        if (tableOfClass[i] == "fullScreen") {
          tableOfClass.splice(i, 1);
        }
      }
      let classDiv = "";
      tableOfClass.forEach((el) => (classDiv += el + " "));
      div.setAttribute("class", classDiv);

      let child = div.lastChild;
      let divChildClass = child.getAttribute("class");
      let childtableOfClass = divChildClass.split(" ");
      for (let i = 0; i < childtableOfClass.length; i++) {
        if (childtableOfClass[i] == "fullScreen") {
          childtableOfClass.splice(i, 1);
        }
      }
      let classDivChild = "";
      childtableOfClass.forEach((el) => (classDivChild += el + " "));
      child.setAttribute("class", classDivChild);
    },
    closeDragElement(e) {
      const div = e.srcElement
      div.style.cursor = "grab"
      document.onmousedown = null;
      document.onmousemove = null;
      this.elemntInDrag = null;
    },
    elementDrag(e) {
      this.position_stack.pos1 = this.position_stack.pos3 - e.clientX;
      this.position_stack.pos2 = this.position_stack.pos4 - e.clientY;
      this.position_stack.pos3 = e.clientX;
      this.position_stack.pos4 = e.clientY;
      e.preventDefault();
      // set the element's new position:
      this.elemntInDrag.style.top =
        this.elemntInDrag.offsetTop - this.position_stack.pos2 + "px";
      this.elemntInDrag.style.left =
        this.elemntInDrag.offsetLeft - this.position_stack.pos1 + "px";
    },
    dragMouseDown(e) {
      let srcDiv = e.srcElement;
      srcDiv.style.cursor = "grabbing"
      this.elemntInDrag = srcDiv.parentNode;
      this.uploadZindex()
      this.elemntInDrag.style.zIndex = 9
      e = e || window.event;
      // get the mouse cursor position at startup:
      this.position_stack.pos3 = e.clientX;
      this.position_stack.pos4 = e.clientY;
      e.preventDefault();
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;

    },
    dragElement(elmnt) {
      let headChild = elmnt.firstChild;
      if (elmnt.firstChild) {
        headChild.onmousedown = this.dragMouseDown;
      }
    },
    updateElementDrag(elmnt) {
      this.elemntInDrag = elmnt;
    },
    uploadZindex() {
      const windows = document.querySelectorAll(".windows")
      windows.forEach((el) => {
        el.style.zIndex = 8
      })
    }
  },
  mounted() {
    const initSysteme = document.getElementById("init1");
    const initInterval = setInterval(() => {
      this.addPoint(initSysteme);
    }, 750);
    setTimeout(() => {
      clearInterval(initInterval);
      this.showInit = false;
    }, 2500);

    const windows = document.getElementsByClassName("windows");
    for (const i in windows) {
      this.dragElement(windows[i]);
    }
    const buttons = document.querySelectorAll(".closeButton");
    buttons.forEach((el) => {
      el.addEventListener("click", this.closeWindow);
    });

    const openButtons = document.querySelectorAll(".openButton");
    openButtons.forEach((el) => {
      el.addEventListener("click", this.emitClick);
    });
  },
};
</script>

<style lang="scss" scoped>
#Informatique {
  width: 100%;
  overflow: hidden;
  .informatique_init {
    position: absolute;
    display: flex;
    margin: auto;
    width: 80%;
    height: 80%;
    top: 15%;
    bottom: 15%;
    left: 0%;
    right: 0%;
    justify-content: center;
    align-items: center;
    color: $InformatiqueText;
    font-family: "Ubuntu";
    font-size: 2.5em;
  }
  .informatique_stacks {
    width: 25%;
    position: absolute;
    top: 15%;
    left: 10%;
    border: 1px solid $InformatiqueBorder;
    min-width: 300px;
    &_miniature {
@include cadreIt;
    }
    h2 {
      text-align: center;
      color: $InformatiquePrimary;
      font-family: "Ubuntu";
      margin: 5px;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .informatique_cursus {
    position: absolute;
    width: 25%;
    min-width: 300px;
    top: 25%;
    left: 50%;
    border: 1px solid $InformatiqueBorder;
    &_miniature {
      @include cadreIt;
      &_display {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        align-items: center;
        &_icone {
          font-size: 3.5em;
        }
        &_text {
          font-family: "Ubuntu", sans-serif;
          padding: 15px;
        }
      }
    }
  }
  .informatique_language {
    position: absolute;
    width: 15%;
    min-width: 200px;
    top: 45%;
    left: 43%;
    border: 1px solid $InformatiqueBorder;
    &_miniature {
      @include cadreIt;
      &_display {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 25px;
        &_text {
          font-family: "Ubuntu";
          text-align: center;
        }
        &_icone {
          font-size: 3em;
          padding: 10px;        
        }
      }
    }
  }
  .informatique_projects {
    position: absolute;
    width: 25%;
    min-width: 250px;
    top: 11%;
    left: 60%;
    border: 1px solid $InformatiqueBorder;
    &_miniature {
      @include cadreIt;
      &_display {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 25px;
        &_text {
          font-family: "Ubuntu";
          text-align: center;
        }
        &_icone {
          font-size: 3em;
          padding: 10px;        
        }
      }
    }
  }
  .informatique_man {
    position: absolute;
    top: 30%;
    left: 15%;
    font-family: "Ubuntu";
    position: absolute;
    width: 70%;
    padding-bottom: 0 !important;
  }
}

.windowHead {
  @include windowIt;
  button {
    cursor: pointer;
    color: black;
    display: flex;
    align-content: center;
    background: transparent;
    border: none;
    font-size: 1.1em;
    margin: 0 5px 0 5px;
  }
}
.windows {
  border: 1px solid $InformatiqueBorder;
}

.fullScreen {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 21% !important;
  width: 100% !important;
  z-index: 9!important
}
.fullScreenChildren {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>