<template>
  <div id="Projects">
    <div class="Project_intro">
      <h2>Mes projets</h2>
      <p>
        Vous pourrez trouver ici tous mes projects personels. Ils ont tous pour
        but de me perfectionner dans les technologies que j'ai pu manier et
        apprendre.
      </p>
    </div>
    <div v-for="(items, name) in projects" :key="items" class="Projects">
      <h3>--- {{ name }} ---</h3>
      <div
        class="Projects_Project"
        v-for="project in items"
        :key="project"
        :id="project.id"
      >
        <div class="Projects_Project_description">
          <h4>{{ project.name }}</h4>
          <div class="Projects_Project_description_display">
            <a
              v-if="project.link"
              :href="project.link"
              target="_blanck"
              rel="noopener noreferrer"
            >
              <font-awesome-icon icon="fa-solid fa-globe" />
            </a>
            <a
              v-if="project.src"
              :href="project.src"
              target="_blanck"
              rel="noopener noreferrer"
              ><font-awesome-icon icon="fa-brands fa-github"
            /></a>
            <font-awesome-icon
              v-if="project.isOpen"
              class="Projects_Project_description_display_icones"
              icon="fas-solid fa-folder-open"
            />
            <font-awesome-icon
              v-if="!project.isOpen"
              class="Projects_Project_description_display_icones"
              icon="fa-solid fa-folder"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectsComponent",
  data() {
    return {
      projects: {
        Personnel: {
          portoFolio: {
            name: "Portofolio",
            id: "portoFolio",
            link: "https://www.jeremytardini.sayadini.fr",
            description:
              "Le site sur lequel vous vous trouvez actuellement. Conçu à l'aide de Vue3, il a pour but de vous présenter toutes les comptétences que j'ai pu enmagasiner jusqu'à aujourd'hui.",
          },
          sporeMusique: {
            name: "Spore Musique",
            id: "sporeMusique",
            link: "https://www.sporemusique.sayadini.fr",
            src: "https://github.com/JeremyTar/SporeMusique",
            description:
              "Site réalisé avec Vue3, VueX et l'API Audio dans le but de proposer une application web permettant la lecteur des mes diférentes réalisations musicales",
            isOpen: false,
          },
          solarSysteme: {
            name: "Solar Systeme",
            id: "solarSysteme",
            link: "https://www.solarsysteme.sayadini.fr",
            src: "https://github.com/JeremyTar/SolarSysteme",
            description:
              "Projets d'initiation à la bibliothéque THREEJS, il a pour but de représenter les différentes planètes de notre systeme solair. Réalisé en Javascript et incluant quelques shaders grace au GLSL et THREEJS ",
            isOpen: false,
          },
          selfcareImmobilier: {
            name: "SelfCare Immobilier",
            id: "selfcareImmobilier",
            link: "https://www.selfcareimmobilier.sayadini.fr",
            src: "https://github.com/JeremyTar/Selfcare-Immobilier",
            description:
              "Refonte intégrale d'un site composé à l'aide de Javascript et de la bibliothéque JQuery. Il sagit de mon premier site professionel et il avait pour but de faire la promotion de mon activité immobiliere ",
            isOpen: false,
          },
          laboiteaJeux: {
            name: "La boite à jeux",
            id: "laboiteaJeux",
            link: "https://www.laboiteajeux.sayadini.fr",
            src: "https://github.com/JeremyTar/Games-Plateforme",
            description:
              "Site web encore en construction, réalisé avez Vue3 qui à pour but de proposer différents jeux. Pour le moment vous pouvez jouer au Pendu et à l'Awale",
            isOpen: false,
          },
          quoteVerba: {
            name: "Quote Verba",
            id: "quoteVerba",
            link: "https://www.quoteverba.sayadini.fr",
            src: "https://github.com/JeremyTar/Compteur-de-mots",
            description:
              "Application web simple en Typescript qui à pour objectif de pouvoir compter et répertorier les mots d'un text",
            isOpen: false,
          },
        },
        Professionnel: {
          onyxCBD: {
            name: "ONYX CBD",
            id: "onyxCBD",
            link: "https://www.onyx-cbd.fr",
            description:
              "Boutique en ligne proposant du CBD. Réalisé à l'aide du CMS Wordpress et de Woocommerce pour la partie boutique. Sa mise en page a quant a elle etait realiser à l'aide du pluging Elementor.",
            isOpen: false,
          },
          FleurDeMadina: {
            name: "Fleur de Madína",
            id: "FleurDeMadina",
            link: "https://www.fleurdemadina.fr",
            description:
              "Site vitrine de Naturopathie et de medecine holistiquesé, il a été réalisé à l'aide du CMS Wordpress et Elementor.",
            isOpen: false,
          },
          martineDeco: {
            name: "Martine Déco",
            id: "martineDeco",
            link: "https://www.martine-deco.fr",
            description:
              "Site vitrine réalisé avec le CMS Wordpress proposant des prestations de décorations de mariages.",
            isOpen: false,
          },
        },
      },
    };
  },
  methods: {
    FolderDisplay(e) {
      const div = e.srcElement;
      const index = div.id;
      for (const i in this.projects) {
        if (i == "Personnel") {
          for (const j in this.projects.Personnel) {
            if (j == index) {
              this.ChangeStyleFolder(div, "Personnel", index);
            }
          }
        } else if (i == "Professionnel") {
          for (const j in this.projects.Professionnel) {
            if (j == index) {
              if (j == index) {
                this.ChangeStyleFolder(div, "Professionnel", index);
              }
            }
          }
        }
      }
    },
    ChangeStyleFolder(Element, objectKey, index) {
      if (objectKey == "Personnel") {
        if (this.projects.Personnel[index].isOpen) {
          this.projects.Personnel[index].isOpen = false;
          this.removeStyle(Element);
        } else if (!this.projects.Personnel[index].isOpen) {
          this.projects.Personnel[index].isOpen = true;
          this.applyStyle(
            Element, 
            this.projects.Personnel[index].description
            );
        }
      } else if (objectKey == "Professionnel") {
        if (this.projects.Professionnel[index].isOpen) {
          this.projects.Professionnel[index].isOpen = false;
          this.removeStyle(Element);
        } else if (!this.projects.Professionnel[index].isOpen) {
          this.projects.Professionnel[index].isOpen = true;
          this.applyStyle(
            Element,
            this.projects.Professionnel[index].description
          );
        }
      }
    },
    removeStyle(Element) {
      const lastChild = Element.lastChild;
      Element.style.height = "35px";
      Element.removeChild(lastChild);
    },
    applyStyle(Element, Content) {
      const description = document.createElement("p");
      description.innerText = Content;
      Element.append(description);
      Element.style.height = "100px";
      description.style.color ="#B7C7BA"
    },
  },
  mounted() {
    const folders = document.querySelectorAll(".Projects_Project");
    folders.forEach((el) => {
      el.addEventListener("mouseenter", this.FolderDisplay);
      el.addEventListener("mouseleave", this.FolderDisplay);
    });
  },
};
</script>

<style lang="scss" scoped>
#Projects {
  font-family: "Ubuntu";
  display: flex;
  height: 65vh;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  overflow-x: hidden;
  .Project_intro {
    text-align: center;
    margin: 25px;
    h2 {
      margin: 10px;
    }
    p {
      color: $InformatiqueText;
    }
  }
  .Projects {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
    h3 {
      font-size: 1.3em;
      border-bottom: 2px solid $InformatiquePrimary;
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
    }
    &_Project {
      border-top: 3px solid $InformatiquePrimary;
      border-left: 3px solid $InformatiquePrimary;
      border-right: 3px solid $InformatiquePrimary;
      border-radius: 15px 15px 0 0;
      width: 80%;
      margin: 5px;
      padding: 10px 20px 10px 20px;
      height: 35px;
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &_description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &_display {
          width: 150px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          button {
            position: relative;
            background-color: transparent;
            z-index: 7;
            cursor: pointer;
          }
          &_icones {
            font-size: 1.5em;
            color: $InformatiquePrimary;
          }
          a {
            color: $InformatiquePrimary;
            font-size: 1.5em;
            transition: 0.2s;
          }
          a:hover {
            color: $InformatiqueText;
          }
        }
      }
      p {
        color: $InformatiqueText;
      }
    }
  }
}
#Projects::-webkit-scrollbar {
    width: 5px;
}
#Projects::-webkit-scrollbar-track{
    background-color: transparent;
}
#Projects::-webkit-scrollbar-thumb {
    background-color: $InformatiqueBackgroundRGBA;
}
</style>